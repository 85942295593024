import {
    SHOW_MESSAGE, UI_LOADING
  } from "../../../constants";
import {
    CHANNEL_BROWSE_ALL_CHANNELS,
    CHANNEL_LIST_MY_CHANNELS,
    CHANNEL_LIST_MY_JOINT_CHANNELS,
    GET_DETAILS_FOR_CHANNEL,
    GET_MEMBERS_OF_CHANNEL,
    LIST_CHANNEL_POSTS,
    LIST_USER_CHANNEL_POSTS_CATEGORY_SELECTED,
    SEND_REQUEST_FOR_JOINING_THE_CHANNEL,
    SHOW_USER_REQUESTS,
    ACCEPT_OR_REJECT_USER_REQUEST,
    CHANNEL_GET_GROUPS_FOR_FILTER,
    CHANNEL_APPLY_GROUP_FILTER,
    GET_CHANNEL_LIST,
    GET_FILTERED_MEMBERS_OF_CHANNEL,
    DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
    DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
    DISABLE_ENABLE_SEARCH_JOINT_CHANNELS,
    DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS,
    DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
    DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
   
} from "../../../constants/UserPlatform/EMChannelConstant"
import API from "../../../../api";
import { EMDoGetChannelPreviewListAction } from "../Home/EMLatestPostPageAction";
import { EMDoGetChannelListByGroupIdAction } from "../Groups/EMGroupsAction";
// import {EMDoGetListOfChannelMembersBySearch} from "../Channels/"
import { handleError } from "../../../../utils/EMCommonFunctions";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../constants/UserPlatform/EMNavigationConstant";
import { GET_DETAILS_OF_GROUP_FOR_NONMEMBERS } from "../../../constants/UserPlatform/EMGroupConstant";
//fetch all available channels
export const EMDoGetListOfMyChannelsAction = action => {
    return dispatch => {
        dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
        API.EMDoGetListOfMyChannels(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_LIST_MY_CHANNELS,
                        payload: response
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS,
                        payload:false
                      })
                      dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching your channels"
                        }
                    });
                    dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                }
            })
            .catch((err) => {
                handleError(dispatch, err)
                dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
            });
    };
};

//  action for member leaving channel
export const EMDoLeaveChannelAction = action => {
    return dispatch => {
      API.EMDoLeaveChannel(action.id)
        .then(data => data.data)
        .then(data => {
          if (data) {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "success",
                message: "You have left the Channel",
              }
            });
            dispatch(EMDoGetChannelDetailsAction({id:action.id}))
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching entity list",
                //key: key
              }
            });
          }
        })
        .catch(err => {
            handleError(dispatch, err)
        });
    };
  };



//get all channel for browsing
export const EMDoGetListOfChannelMembersBySearchAction = action => {
    return dispatch => {
        API.EMDoGetListOfChannelMembersBySearch( action.searchText, action.id, action.limit)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: GET_FILTERED_MEMBERS_OF_CHANNEL,
                        payload: {response : response.results, count:response.count,limit:action.limit}
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
                        payload:false
                      })
                      dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while groups members"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//fetch joint channels
export const EMDoGetListOfMyJointChannelsAction = action => {
    return dispatch => {
        dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
        API.EMDoGetListOfMyJointChannels(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_LIST_MY_JOINT_CHANNELS,
                        payload: response
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_JOINT_CHANNELS,
                        payload:false
                      })
                      dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching your joint channels"
                        }
                    });
                    dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
                dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
            });
    };
};

//browse all channels
export const EMDoGetListOfAllChannelsAction = action => {
    return dispatch => {
        API.EMDoGetListOfAllChannels(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_BROWSE_ALL_CHANNELS,
                        payload: { response: response.results, limit: action.limit },
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
                        payload:false
                      })
                      dispatch({
                        type:DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//get group action
export const EMDoActionForChannelAction = action => {
    
    return dispatch => {
        API.EMDoActionForChannel(action.id, action.myaction)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    // dispatch({
                    //     type: ACTION_FOR_GROUPS,
                    //     payload: {response :response, route :action.route, postIndex:action.postIndex}
                    // });
                    if(action.route === "internal_channels") {
                        dispatch(EMDoGetListOfMyChannelsAction());
                    }
                    if(action.route === "joint_channels"){
                        dispatch(EMDoGetListOfMyJointChannelsAction());
                    }
                    if(action.route === "channel_details") {
                        dispatch(EMDoGetChannelDetailsAction({ id: action.id }));
                    }if(action.route === "home"){
                        dispatch(EMDoGetChannelPreviewListAction())
                    }if (action.usedIn === "channels"){
                        dispatch(EMDoGetChannelsListAction())
                    }if(action.usedIn === "groups"){
                        dispatch(EMDoGetChannelListByGroupIdAction({groupId:window.sessionStorage.getItem("GroupId")}))
                    }
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while submitting the action"
                        }
                    });
                }
            })
            .catch(err => {
               
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetListOfMyJointChannelsBySearchAction = action => {
    return dispatch => {
        dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
        API.EMDoGetListOfMyJointChannelsBySearch(action.searchText)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_LIST_MY_JOINT_CHANNELS,
                        payload: response
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_JOINT_CHANNELS,
                        payload:false
                      })
                      dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching joint channels"
                        }
                    });
                    dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
                dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
            });
    };
};

export const EMDoGetListOfMyChannelsBySearchAction = action => {
    return dispatch => {
        dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
        API.EMDoGetListOfMyChannelsBySearch(action.searchText)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_LIST_MY_CHANNELS,
                        payload: response
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS,
                        payload:false
                      })
                      dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching joint channels"
                        }
                    });
                    dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
                dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
            });
    };
};

export const EMDoGetListOfAllChannelsBySearchAction = action => {
    return dispatch => {
        API.EMDoGetListOfAllChannelsBySearch(action.searchText)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: CHANNEL_BROWSE_ALL_CHANNELS,
                        payload: { response: response.results, limit: action.limit },
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching joint channels"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetChannelDetailsAction = action => {
    return dispatch => {
        
        API.EMDoGetChannelDetails(action.id)
            .then(data => data.data)
            .then(response => {
                console.log("response_to_check",response?.is_member_of_group)
               
                    dispatch({
                        type: GET_DETAILS_OF_GROUP_FOR_NONMEMBERS,
                        payload: response?.is_member_of_group,
                      }) 
                
                if(!response?.is_channel_user){
                    dispatch({
                              type: CHANGE_ELEMENT_FOR_SUBTABS,
                              payload: {
                                path: "/users/channels",
                                element: "channels/",
                                remove: "Members",
                              },
                            });
                
            }
                if (response) {
                    dispatch({
                        type: GET_DETAILS_FOR_CHANNEL,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching joint channels"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//get post of a channel
export const EMDoGetChannelPostsAction = action => {  
    return dispatch => {
        API.EMDoGetChannelPosts(action.id,action.page)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIST_CHANNEL_POSTS,
                        payload: {response :response,userData :action.userData, page:action.page}
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching posts"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//action for category filtered post list
export const EMDoGetCategoryFilteredPostForChannelListAction = action => {
    return dispatch => {
        API.EMDoGetCategoryFilteredPostForChannelList(action.id, action.selectedDataOption, action.page)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIST_CHANNEL_POSTS,
                        payload: {response :response,userData :action.userData, page:action.page}
                    });
                    if(response.results.length === 0){
                        dispatch({
                          type:SHOW_MESSAGE,
                          payload: {
                            type: "info",
                            message: "No record found"
                        }
                        })
                      }
                } else { 
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//send request for joining the group
export const EMDoRequestToJoinChannelAction = action => { 
    return dispatch => {
        API.EMDoRequestToJoinChannel(action.id)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: SEND_REQUEST_FOR_JOINING_THE_CHANNEL,
                        payload: response
                    });
                    dispatch(EMDoGetChannelDetailsAction({ id: action.id }));
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "info",
                            message: "Request sent successfully"
                        }
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while sending request to join group"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetMembersOfChannelAction = action => { 
    return dispatch => {
        API.EMDoGetMembersOfChannel(action.id, action.limit)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: GET_FILTERED_MEMBERS_OF_CHANNEL,
                        payload: {response : response.results, count:response.count,limit:action.limit}
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
                        payload:false
                      })
                      dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching the channel members"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//get user requests
export const EMDoGetUserRequestsForChannelAction = action => {
    
    return dispatch => {
        API.EMDoGetUserRequestsForChannel(action.id)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: SHOW_USER_REQUESTS,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching user requests"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//accept or reject request for joining the group
export const EMDoAcceptRejectUserRequestForChannelAction = action => {
    return dispatch => {
        API.EMDoAcceptRejectUserRequestForChannel(action.requestId, action.myaction,)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: ACCEPT_OR_REJECT_USER_REQUEST,
                        payload: {response:response, requestId:action.requestId}
                    });
                    // dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
                    if(action.myaction === "accept") {
                        dispatch({
                            type: SHOW_MESSAGE,
                            payload: {
                                type: "info",
                                message: "Request accepted"
                            }
                        });
                    } else if(action.myaction === "reject") {
                        dispatch({
                            type: SHOW_MESSAGE,
                            payload: {
                                type: "info",
                                message: "Request rejected"
                            }
                        }); 
                    }
                 
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while sending request to join group"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetGroupsForFilterInChannelAction = action => {
    return dispatch => {
            API.EMDoGetGroupsForFilterInChannel(action)
            .then(data => data.data)
            .then(response => {
                
                if (response) {
                    
                    dispatch({
                        type: CHANNEL_GET_GROUPS_FOR_FILTER,
                        payload: response 
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching groups"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
        }
    };

    export const EMDoGetChannelsByGroupFilterAction = action => {
        return dispatch => {
                API.EMDoGetChannelsByGroupFilter(action)
                .then(data => data.data)
                .then(response => {
                    
                    if (response) {
                        
                        dispatch({
                            type: CHANNEL_BROWSE_ALL_CHANNELS,
                            payload: { response: response.results, limit: action.limit },
                        });
                        dispatch({
                            type:DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
                            payload:false
                          })
                          dispatch({
                            type:DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
                            payload:false
                          })
                    } else {
                        dispatch({
                            type: SHOW_MESSAGE,
                            payload: {
                                type: "error",
                                message: "Error while fetching channels"
                            }
                        });
                    }
                })
                .catch(err => {
                    handleError(dispatch, err)
                });
            }
            }

            export const EMDoGetMembersOfChannelByGroupFilterAction = action => { 
                return dispatch => {
                    API.EMDoGetMembersOfChannelByGroupFilter(action.id, action.limit, action.action)
                        .then(data => data.data)
                        .then(response => {
                            if (response) {
                                dispatch({
                                    type: GET_FILTERED_MEMBERS_OF_CHANNEL,
                                    payload: {response : response.results, count:response.count,limit:action.limit}
                                });
                                dispatch({
                                    type:DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
                                    payload:false
                                  })
                                  dispatch({
                                    type:DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
                                    payload:false
                                  })
                            } else {
                                dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                        type: "error",
                                        message: "Error occured while fetching the channel members"
                                    }
                                });
                            }
                        })
                        .catch(err => {
                            handleError(dispatch, err)
                        });
                };
            };

            //get channel list
export const EMDoGetChannelsListAction = action => {
    return dispatch => {
        API.EMDoGetChannelList(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: GET_CHANNEL_LIST,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching available channels"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};
export const EMDoGetEmailNotificationChannelAction = (action) => {
    return (dispatch) => {
      const emailBoday ={
        "email_notification" : action.is_email_notification
    }
      API.EMDoGetEmailNotificationChannelsApi(emailBoday,action.id)
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            dispatch(EMDoGetChannelDetailsAction({ id: action.id }));
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching email notification",
              },
            });
          }
        })
        .catch((err) => {
            handleError(dispatch, err)
        });
    };
  };
import { SHOW_MESSAGE, UI_LOADING } from "../../../constants";
import {
  GROUP_LIST_MY_GROUPS,
  GROUP_LIST_MY_JOINT_GROUPS,
  GROUP_BROWSE_ALL_GROUPS,
  GROUP_NETWORK_FILTER,
  GET_DETAILS_FOR_GROUP,
  GET_CHANNEL_LIST,
  GET_MEMBERS_OF_GROUP,
  LIST_GROUP_POSTS,
  LIST_USER_GROUP_POSTS_CATEGORY_SELECTED,
  FILTERED_GROUPS,
  GET_FILTERED_MEMBERS_OF_GROUP,
  ACTION_FOR_GROUPS,
  SHOW_USER_REQUESTS,
  SEND_REQUEST_FOR_JOINING_THE_GROUP,
  ACCEPT_OR_REJECT_USER_REQUEST,
  GROUP_LIST_MY_JOINT_GROUPS_TOTAL_NUMBER,
  LEAVE_GROUP,
  DISABLE_ENABLE_FILTER,
  DISABLE_ENABLE_SEARCH_INTERNAL_GROUPS,
  DISABLE_ENABLE_SEARCH_JOINT_GROUPS,
  DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
  DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
} from "../../../constants/UserPlatform/EMGroupConstant";
import API from "../../../../api";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { EMDoGetChannelPreviewListAction } from "../Home/EMLatestPostPageAction";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../constants/UserPlatform/EMNavigationConstant";
import { handleError } from "../../../../utils/EMCommonFunctions";

//Get my internal groups
export const EMDoGetListOfMyGroupsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetListOfMyGroups(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_LIST_MY_GROUPS,
            payload: { response: response.results, limit: action.limit, count:response.count },
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_INTERNAL_GROUPS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching groups",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Get my internal groups
export const EMDoGetListOfMyGroupsBySearchAction = (action) => {
  return (dispatch) => {
    API.EMDoGetListOfMyGroupsBySearch(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_LIST_MY_GROUPS,
            payload: { response: response.results, limit: action.limit, count:response.count },
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_INTERNAL_GROUPS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching groups",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get all groups for browsing
export const EMDoGetListOfAllGroupsAction = (action) => {
  
  return (dispatch) => {
    API.EMDoGetListOfAllGroups(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_BROWSE_ALL_GROUPS,
            payload: { response: response.results, limit: action.limit },
          });
          dispatch({
            type:DISABLE_ENABLE_FILTER,
            payload:false
          })
          dispatch({
            type:DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching groups",
            },
          });
        }
      })
      .catch((err) => {
      handleError(dispatch, err)
      });
  };
};

// action for member leaving group
export const EMDoGetMemberLeaveGroupAction = (action) => {
  return (dispatch) => {
    API.EMDoGetMemberLeaveGroup(action.id)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "You have left the group",
            },
          });
          dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              //key: key
            },
          });
        }
      })
      .catch((err) => {
       
          handleError(dispatch, err)
       
      });
  };
};

//get all groups for browsing
export const EMDoGetListOfAllGroupsBySearchAction = (action) => {
  return (dispatch) => {
    API.EMDoGetListOfAllGroupsBySearch(action.searchText)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_BROWSE_ALL_GROUPS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching groups",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get my joint groups
export const EMDoGetListOfMyJointGroupsAction = (action) => {
  
  return (dispatch) => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
    API.EMDoGetListOfMyJointGroups(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_LIST_MY_JOINT_GROUPS,
            payload: response,
          });
          dispatch({
            type: GROUP_LIST_MY_JOINT_GROUPS_TOTAL_NUMBER,
            payload: response,
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_JOINT_GROUPS,
            payload:false
          })
          dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint groups",
            },
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
        dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
      });
  };
};

//get my joint groups
export const EMDoGetListOfMyJointGroupsBySearchAction = (action) => {
  return (dispatch) => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"} });
    API.EMDoGetListOfMyJointGroupsBySearch(action.searchText)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_LIST_MY_JOINT_GROUPS,
            payload: response,
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_JOINT_GROUPS,
            payload:false
          })
          dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint groups",
            },
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
        dispatch({ type: UI_LOADING, payload: {state: false, message:""} });
      });
  };
};

//get network filter
export const EMDoGetNetworkFiltersAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworkFilters(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_NETWORK_FILTER,
            payload: { response: response, route: action.route },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get network filter
export const EMDoGetNetworksByGroupIdAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworksByGroupId(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_NETWORK_FILTER,
            payload: { response: response, route: action.route },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};


//get filtered groups using organization
export const EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction = (
  action
) => {
  return (dispatch) => {
    API.EMDoGetFilteredGroupsWithPartenershipsAndOrganizations(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GROUP_BROWSE_ALL_GROUPS,
            payload: { response: response.results, limit: action.limit },
          });
          dispatch({
            type:DISABLE_ENABLE_FILTER,
            payload:false
          })
          dispatch({
            type:DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching filtered groups",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get details for a group
export const EMDoGetGroupDetailsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetGroupDetails(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_DETAILS_FOR_GROUP,
            payload: response,
          });
          if (response && (response.is_group_user == false)) {
            dispatch({
              type: CHANGE_ELEMENT_FOR_SUBTABS,
              payload: {
                path: "/users/groups",
                element: "groups/",
                remove: "Members",
              },
            });
            if (response && response.privacy_type_name == "Private") {
              dispatch({
                type: CHANGE_ELEMENT_FOR_SUBTABS,
                payload: {
                  path: "/users/groups",
                  element: "groups/browsegroups/",
                  remove: "Members",
                },
              });
              
            }
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get channel list
export const EMDoGetChannelListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetChannelList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_CHANNEL_LIST,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get members of a group
export const EMDoGetMembersOfGroupAction = (action) => {
  return (dispatch) => {
    API.EMDoGetMembersOfGroup(action.id, action.limit)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_FILTERED_MEMBERS_OF_GROUP,
            payload: { response: response.results, limit: action.limit },
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
            payload:false
          })
          dispatch({
            type:DISABLE_ENABLE_FILTER,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available members",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get filtered group members using organization
export const EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction =
  (action) => {
    return (dispatch) => {
      API.EMDoGetFilteredGroupMembersWithOrganizationAndPartnerships(
        action.myaction,
        action.id,
        action.limit
      )
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            dispatch({
              type: GET_FILTERED_MEMBERS_OF_GROUP,
              payload: { response: response.results, limit: action.limit },
            });
            dispatch({
              type:DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
              payload:false
            })
            dispatch({
              type:DISABLE_ENABLE_FILTER,
              payload:false
            })
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching filtered groups",
              },
            });
          }
        })
        .catch((err) => {
          handleError(dispatch, err)
        });
    };
  };

//get group channels
export const EMDoGetChannelListByGroupIdAction = (action) => {
  return (dispatch) => {
    API.EMDoGetChannelListByGroupId(action.groupId)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_CHANNEL_LIST,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get group action
export const EMDoActionForGroupAction = (action) => {
  return (dispatch) => {
    API.EMDoActionForGroup(action.id, action.myaction)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          // dispatch({
          //     type: ACTION_FOR_GROUPS,
          //     payload: {response :response, route :action.route, postIndex:action.postIndex}
          // });
          if (action.route === "internal_groups") {
            dispatch(EMDoGetListOfMyGroupsAction());
          }
          if (action.route === "joint_groups") {
            dispatch(EMDoGetListOfMyJointGroupsAction());
          }
          if (action.route === "browse_groups") {
            dispatch(EMDoGetListOfAllGroupsAction());
          }
          if (action.route === "group_details") {
            dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
          } 
          if (action.route === "home") {
            dispatch(EMDoGetChannelPreviewListAction());
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while submitting the action",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get all groups for browsing
export const EMDoGetListOfGroupMembersSearchAction = (action) => {
  return (dispatch) => {
    API.EMDoGetListOfGroupMembersBySearch(
      action.id,
      action.searchText,
      action.limit
    )
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_FILTERED_MEMBERS_OF_GROUP,
            payload: { response: response.results, limit: action.limit },
          });
          dispatch({
            type:DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
            payload:false
          })
          dispatch({
            type:DISABLE_ENABLE_FILTER,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while groups members",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//accept or reject request for joining the group
export const EMDoAcceptRejectUserRequestAction = (action) => {
  return (dispatch) => {
    API.EMDoAcceptRejectUserRequest(action.requestId, action.myaction)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ACCEPT_OR_REJECT_USER_REQUEST,
            payload: { response: response, requestId: action.requestId },
          });
          // dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
          if (action.myaction === "accept") {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "info",
                message: "Request accepted",
              },
            });
          } else if (action.myaction === "reject") {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "info",
                message: "Request rejected",
              },
            });
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while sending request to join group",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//send request for joining the group
export const EMDoRequestToJoinGroupAction = (action) => {
  return (dispatch) => {
    API.EMDoRequestToJoinGroup(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SEND_REQUEST_FOR_JOINING_THE_GROUP,
            payload: response,
          });
          dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "info",
              message: "Request sent successfully",
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while sending request to join group",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get user requests
export const EMDoGetUserRequestsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetUserRequests(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_USER_REQUESTS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user requests",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get post of a group
export const EMDoGetGroupPostsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetGroupPosts(action.id, action.page)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_GROUP_POSTS,
            payload: {
              response: response,
              userData: action.userData,
              page: action.page,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching posts",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

//action for category filtered post list
export const EMDoGetCategoryFilteredPostForGroupListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCategoryFilteredPostForGroupList(
      action.id,
      action.selectedDataOption,
      action.page
    )
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_GROUP_POSTS,
            payload: {
              response: response,
              userData: action.userData,
              page: action.page,
            },
          });
          if (response.results.length === 0) {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "info",
                message: "No record found",
              },
            });
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
export const EMDoGetEmailNotificationGroupsAction = (action) => {
  return (dispatch) => {
    const emailBoday ={
      "email_notification" : action.is_email_notification
  }
    API.EMDoGetEmailNotificationGroupsApi(emailBoday,action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch(EMDoGetGroupDetailsAction({ id: action.id }));
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching email notification",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get group details for editing

// export const EMDoGetGroupDetailsForEditingAction = action => {
//     return dispatch => {
//         API.EMDoGetGroupDetailsForEditing(action.id)
//             .then(data => data.data)
//             .then(response => {
//                 if (response) {
//                     dispatch({
//                         type: DETAILS_FOR_GROUP_EDIT,
//                         payload: response
//                     });
//                 } else {
//                     dispatch({
//                         type: SHOW_MESSAGE,
//                         payload: {
//                             type: "error",
//                             message: "Error occured while fetching details for group"
//                         }
//                     });
//                 }
//             })
//     };
// };

import {
  Paper,
  SvgIcon,
  Button,
  GridList,
  GridListTile,
  Box,
  Avatar,
  CardContent,
  Grid,
  useMediaQuery,
  isWidthUp,
  withWidth,
  Tooltip,
} from "@material-ui/core";
import {
  GROUP_EDIT,
  LEAVE_GROUP,
} from "../../../../../redux/constants/UserPlatform/EMGroupConstant";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import EmailIcon from "@material-ui/icons/Email";
import CreateIcon from "@material-ui/icons/Create";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import StarRateIcon from "@material-ui/icons/StarRate";
import { useSelector, useDispatch } from "react-redux";
import EMCreatePost from "../../../../../components/user/EMCreatePost";
import EMStartTyping from "../../../../../components/user/EMStartTyping";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PublicIcon from "@material-ui/icons/Public";
import Parse from "html-react-parser";
import CheckIcon from "@material-ui/icons/Check";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  EMDoDownloadAttachmentsForGroupAction,
  EMDoGetGroupDetailsByIdAction,
} from "../../../../../redux/actions/EMGroupAdminActions";
import EMGroupSettings from "../../../../GroupAdmin/groupManagement/groupSettings/index";
import EMCreateEvent from "../../../../../components/user/EMCreateEvent";
import { findLast } from "lodash";
import groupAdmin from "../../../../../api/groupAdmin";
import {
  EMDoRequestToJoinGroupAction,
  EMDoGetMemberLeaveGroupAction,
} from "../../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
// import {EMDoGetGroupDetailsAction} from "../../../../../redux/actions/UserPlatform/Groups"
import groupImage from "../../../../../assets/group_image.jpg";
import bannerImage from "../../../../../assets/banner_image.jpg";
import EMCreatePoll from "../../../../../components/user/EMCreatePoll";
import EMCreateOpenPoll from "../../../../../components/user/EMCreateOpenPoll";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CloudDownload } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { useNavigate } from "react-router";
import EMCreateQuiz from "../../../../../components/user/EMCreateQuiz";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  button: {
    width: "200px",
    height: "40px",
    backgroundColor: "#3399ff",
    color: "white",
  },
  card: {
    width: "60%",
    position: "relative",
  },
  paper: {
    // marginTop: "2%",
    // marginLeft: "4%",
  },
  topRight: {
    position: "absolute",
    top: "8px",
    right: " 16px",
  },
  groupInfo: {
    textAlign: "left",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
    fontSize: "medium",
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-word",
    overflow: "hidden",
    // marginBottom:"1%"
  },
  rootForOrganizations: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForOrganizations: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  groupCardInfo: {
    marginLeft: "1%",
    maxWidth: "65%",
  },
  gridImages: {
    marginLeft: "15%",
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    width: "100%",
    // height: window.screen.height/7,
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  gridListTile: {
    marginTop: "2%",
  },
  rootForGrid: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "2%",
    height: "50%",
  },
  rootForAttachments: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForAttachments: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo1: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

function EMGroupDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.groups || {});
  const groupInfo = useSelector((state) => state.groupAdmin["groupDetails"]);
  const [requestToJoin, setRequestToJoin] = React.useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const entityBasedPermissions = useSelector(
    (state) => state.auth.rolesAndPermissionsForSpecificEntity
  );

  const theme = useTheme();
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function getColsForAdm(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 3;
    }
    return 4;
  }

  const colsForAdm = getColsForAdm(props.width);

  function getColsForOrg(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 4;
    }
    return 4;
  }

  const colsForOrg = getColsForOrg(props.width);

  useEffect(() => {
    let manageProfilePage =
      entityBasedPermissions &&
      entityBasedPermissions.find(
        (perm) => perm.permission_name == "manage_profile_page"
      );
    if (manageProfilePage) {
      setIsAdmin(manageProfilePage.edit_flag);
    }
  }, [data.singleGroup, entityBasedPermissions]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(
        EMDoGetGroupDetailsByIdAction({
          id: window.sessionStorage.getItem("GroupId"),
          key: "fetch_group_details",
          usedIn: "Activity Feed",
        })
      );
    }
  }, [isAdmin]);

  return (
    <div>
      <Paper elevation={2} className={classes.paper}>
        <Card style={{ width: "100%", padding: "1%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "#d3d3d3",
            }}
          >
            <img
              alt=""
              style={{
                flexShrink: 0,
                minWidth: "100%",
                minHeight: "100%",
              }}
              src={
                props.groupBanner
                  ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                    props.groupBanner
                  : bannerImage
              }
            />
          </div>
          <Grid container style={{ paddingTop: "1%" }}>
            <Grid container item lg={8} md={8} sm={12} xs={12} spacing={1}>
              <Grid item lg={3} md={3} sm={5} xs={5}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    // height: "100%",
                    backgroundColor: "#d3d3d3",
                  }}
                >
                  <img
                    alt=""
                    style={{
                      flexShrink: 0,
                      minWidth: "100%",
                      minHeight: "100%",
                    }}
                    src={
                      props.groupLogo
                        ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                            0,
                            -1
                          ) + props.groupLogo
                        : groupImage
                    }
                  />
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={5} xs={5}>
                <Typography className={classes.groupInfo}>
                  {props.groupName}
                </Typography>
                {data.groupInfo ? (
                  <Typography>
                    {data.singleGroup?.is_group_user &&
                      props.groupDescription &&
                      Parse(props.groupDescription)}
                  </Typography>
                ) : null}

                <Box style={{ marginBottom: "1%" }}>
                  {props.groupPrivacy === "Public" ? (
                    <Box display="flex" alignItems="center">
                      <PublicIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Public Group
                      </Typography>
                    </Box>
                  ) : null}
                  {props.groupPrivacy === "Restricted" ? (
                    <Box display="flex" alignItems="center">
                      <LockIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Restricted Group
                      </Typography>
                    </Box>
                  ) : null}
                  {props.groupPrivacy === "Private" ? (
                    <Box display="flex" alignItems="center">
                      <LockOpenIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Private Group
                      </Typography>
                    </Box>
                  ) : null}
                  {props.groupPrivacy === "Secret" ? (
                    <Box display="flex" alignItems="center">
                      <SecretIcon
                        style={{ color: "#3399ff", marginTop: "2%" }}
                      />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Secret Group
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Typography
                  style={{ fontWeight: "bolder", marginBottom: "1%" }}
                  // onClick={onMemberClick}
                >
                  {props.groupMembers} members
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={2} xs={2}>
                <>
                  <IconButton onClick={props.onInfoClick}>
                    <InfoIcon />
                  </IconButton>
                  {props.is_subscribed ? (
                    <IconButton onClick={props.onNotificationClick}>
                      <NotificationsIcon color="secondary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={props.onNotificationClick}>
                      <NotificationsOffIcon color="secondary" />
                    </IconButton>
                  )}
                  {props.is_fav ? (
                    <IconButton aria-label="pin" onClick={props.onStarClick}>
                      <StarRateIcon color="secondary" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="pin" onClick={props.onStarClick}>
                      <StarRateIcon style={{ color: "#C8CDD0" }} />
                    </IconButton>
                  )}
                  {props.isGroupUser ? (
                    props.is_email_notification ? (
                      <IconButton onClick={props.onEmailNotificationClick}>
                        <EmailIcon color="secondary" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={props.onEmailNotificationClick}>
                        <UnsubscribeIcon color="secondary" />
                      </IconButton>
                    )
                  ) : (
                    ""
                  )}
                </>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: matchesSmallScreen ? "center" : "",
                  marginTop: matchesSmallScreen ? "2%" : "",
                }}
              >
                {data.singleGroup.is_group_user ? (
                  isAdmin ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<CreateIcon />}
                      onClick={() => {
                        dispatch({
                          type: GROUP_EDIT,
                          payload: true,
                        });
                      }}
                    >
                      Edit Group Details
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      color="secondary"
                      disabled={props.groupName.includes("HQ_HUB")}
                      // startIcon={<CreateIcon />}
                      onClick={() => {
                        dispatch(
                          EMDoGetMemberLeaveGroupAction({
                            id: window.sessionStorage.getItem("GroupId"),
                          })
                        );
                      }}
                    >
                      Leave Group
                    </Button>
                  )
                ) : (
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<CheckIcon />}
                    onClick={() => {
                      setRequestToJoin(true);
                      dispatch(
                        EMDoRequestToJoinGroupAction({
                          id: data.singleGroup.id
                            ? data.singleGroup.id
                            : window.sessionStorage.getItem("GroupId"),
                        })
                      );
                    }}
                    disabled={
                      data.singleGroup.join_request_status === "1" ||
                      requestToJoin === true
                    }
                  >
                    {data.singleGroup.join_request_status === "1" ||
                    requestToJoin === true
                      ? "Request Sent"
                      : props.groupPrivacy == "Public"
                      ? "Join"
                      : "Request to join"}
                  </Button>
                )}
              </Box>
              <Box p={2}>
                <Box style={{ marginTop: "2%" }}>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ fontWeight: "bolder", marginBottom: "2%" }}
                  >
                    Organizations
                  </Typography>
                </Box>
                <div
                  className={classes.rootForOrganizations}
                  style={{ marginTop: "1%" }}
                >
                  <GridList
                    className={classes.gridListForOrganizations}
                    cols={colsForOrg}
                  >
                    {props.groupOrganizations &&
                      props.groupOrganizations.map((group, index) => (
                        <GridListTile
                          key={index}
                          style={{
                            overflow: "hidden",
                            width: "60px",
                            height: "60px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Paper
                            elevation={2}
                            style={{
                              width: "48px",
                              height: "48px",
                              // margin: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {group.logo_file ? (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                    height: "100%",
                                    verticalAlign: "middle",
                                  }}
                                ></span>
                                <img
                                  alt=""
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    verticalAlign: "middle",
                                  }}
                                  src={
                                    process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                      0,
                                      -1
                                    ) + group.logo_file
                                  }
                                />
                              </div>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Avatar
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  {group.organization_name
                                    .charAt(0)
                                    .toUpperCase()}
                                </Avatar>
                              </Box>
                            )}
                          </Paper>
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
              </Box>
            </Grid>
          </Grid>
          {!data.singleGroup?.is_group_user || data.groupInfo ? (
            <Card style={{ width: "100%" }}>
              <CardContent>
                <div
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginBottom: "1%",
                  }}
                >
                  <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                    <Typography paragraph={true}>
                      {props.groupVision && Parse(props.groupVision)}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      width: matchesSmallScreen ? "" : "50%",
                      marginLeft: matchesSmallScreen ? "0%" : "2%",
                      marginBottom: matchesSmallScreen ? "1%" : "0%",
                    }}
                  >
                    <Typography paragraph={true}>
                      {props.groupMission && Parse(props.groupMission)}
                    </Typography>
                  </Paper>
                </div>
                <div
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginBottom: "1%",
                  }}
                >
                  <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                    <Typography paragraph={true}>
                      {data.singleGroup?.is_group_user &&
                        props.groupAdditionalInfo &&
                        Parse(props.groupAdditionalInfo)}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      width: matchesSmallScreen ? "" : "50%",
                      marginLeft: matchesSmallScreen ? "0%" : "2%",
                      marginBottom: matchesSmallScreen ? "1%" : "0%",
                    }}
                  >
                    {data.singleGroup?.is_group_user &&
                    data.singleGroup.attachments.length > 0 ? (
                      <div className={classes.rootForAttachments}>
                        <GridList
                          cellHeight="auto"
                          className={classes.gridListForAttachments}
                          cols={1}
                        >
                          {data.singleGroup.attachments.map((doc, id) => (
                            <Box display="flex">
                              <Typography>
                                <a
                                  href={
                                    process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                    doc.file
                                  }
                                  style={{ color: "#2F92D6" }}
                                  target="_blank"
                                >
                                  {doc &&
                                    doc.file.substr(
                                      doc && doc.file.lastIndexOf("/") + 1,
                                      doc && doc.length
                                    )}
                                </a>
                              </Typography>
                            </Box>
                          ))}
                        </GridList>
                      </div>
                    ) : null}
                  </Paper>
                </div>
              </CardContent>
            </Card>
          ) : props.isGroupUser || props.groupPrivacy == "Public" ? (
            <Box style={{ marginTop: "1%" }}>
              {console.log(
                props.isGroupUser,
                props.groupPrivacy,
                data.showCreatePost
              )}
              {(data.singleGroup?.is_group_user  && data.showCreatePost.value) ? (
                data.showCreatePost.route === "create_post" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <EMCreatePost
                      forGroup={true}
                      groupName={props.groupName}
                      selectedChannelIds={[
                        `${props.groupName} |${data.singleGroup.main_channel}`,
                      ]}
                    />
                  </Box>
                ) : data.showCreatePost.route === "create_event" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <EMCreateEvent
                      forGroup={true}
                      groupName={props.groupName}
                      selectedChannelIds={[
                        `${props.groupName} |${data.singleGroup.main_channel}`,
                      ]}
                    />
                  </Box>
                ) : data.showCreatePost.route === "create_quiz" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <EMCreateQuiz
                      forGroup={true}
                      groupName={props.groupName}
                      selectedChannelIds={[
                        `${props.groupName} |${data.singleGroup.main_channel}`,
                      ]}
                    />
                  </Box>
                ) : data.pollWithOptions ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <EMCreatePoll
                      forGroup={true}
                      groupName={props.groupName}
                      selectedChannelIds={[
                        `${props.groupName} |${data.singleGroup.main_channel}`,
                      ]}
                    />
                  </Box>
                ) : (
                  <Box style={{ marginBottom: "2%" }}>
                    <EMCreateOpenPoll
                      selectedChannelIds={[
                        `${props.groupName} |${data.singleGroup.main_channel}`,
                      ]}
                      forGroup={true}
                      groupName={props.groupName}
                    />
                  </Box>
                )
              ) : (
                <Box style={{ marginBottom: "2%" }}>
                  <EMStartTyping
                    usedIn="groups"
                    context_name={data.singleGroup.group_name}
                  />
                </Box>
              )}
            </Box>
          ) : null}
        </Card>
        <EMGroupSettings
          actionChange={true}
          open={data.editGroup}
          close={() => {
            dispatch({
              type: GROUP_EDIT,
              payload: false,
            });
          }}
        />
      </Paper>
      {data.groupInfo ? (
        <Paper elevation={2} style={{ marginTop: "2%" }}>
          <Grid
            container
            direction="row"
            style={{ padding: 10 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={2} md={2} sm={3} xs={5}>
              <Typography
                component="h6"
                variant="h6"
                style={{ fontWeight: "bolder" }}
              >
                Group Admins
              </Typography>
            </Grid>
            <Grid item lg={10} md={10} sm={9} xs={7}>
              <div className={classes.root}>
                <GridList
                  className={classes.gridList}
                  // style={{ alignItems: "center", }}
                  cellHeight="auto"
                  cols={colsForAdm}
                >
                  {props.groupAdmins &&
                    props.groupAdmins.map((admin, index) => (
                      <GridListTile className={classes.gridListTile1}>
                        <Avatar
                          onClick={
                            () =>
                              navigate(`/users/people/${parseInt(admin.id)}`, {
                                state: {
                                  name: admin.first_name,
                                  id: parseInt(admin.id),
                                },
                              })
                            // dispatch(EMDoGetProfileDetailsAction(loginUserData.profile_id))
                          }
                          style={{
                            margin: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "48px",
                            width: "48px",
                            cursor: "pointer",
                          }}
                          src={
                            process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                              0,
                              -1
                            ) + admin.profile_pic_file
                          }
                        />
                        <Tooltip
                          placement="top"
                          title={
                            <h2 style={{ color: "white" }}>
                              {admin.first_name + " " + admin.last_name}
                            </h2>
                          }
                        >
                          <Typography
                            className={classes.typo1}
                            style={{ textAlign: "center", marginTop: "5%" }}
                          >
                            {admin.first_name} {admin.last_name}
                          </Typography>
                        </Tooltip>
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
    </div>
  );
}

function SecretIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.227,9.819l.761-1.994a.509.509,0,0,0-.478-.691H10.647A4.038,4.038,0,0,0,11.214,5.1v-.01c1.248-.248,2.038-.608,2.038-1.01,0-.424-.869-.8-2.233-1.051A8.262,8.262,0,0,0,9.726.389,1.022,1.022,0,0,0,8.468.109l-.879.44a1.019,1.019,0,0,1-.911,0L5.8.109a1.022,1.022,0,0,0-1.258.28A8.329,8.329,0,0,0,3.249,3.026c-1.36.252-2.229.627-2.229,1.051,0,.4.79.761,2.038,1.01V5.1a4.038,4.038,0,0,0,.567,2.038H1.793a.509.509,0,0,0-.468.71l.822,1.917A4.266,4.266,0,0,0,0,13.453V14.88a1.429,1.429,0,0,0,1.427,1.427H12.841a1.429,1.429,0,0,0,1.427-1.427V13.453a4.268,4.268,0,0,0-2.041-3.634ZM5.605,15.288,4.28,9.173l1.58,1.019.764,1.274Zm3.057,0L7.644,11.466l.764-1.274,1.58-1.019ZM9.991,5.781a2.342,2.342,0,0,1-.525,1.064c-.322.3-1.529.713-2.038-.8a.312.312,0,0,0-.583,0c-.541,1.6-1.784,1.032-2.038.8A2.311,2.311,0,0,1,4.28,5.781c-.025-.08-.2-.182-.2-.185V5.252a24.526,24.526,0,0,0,3.057.185,23.929,23.929,0,0,0,3.057-.185V5.6S10.016,5.7,9.991,5.781Z" />
    </SvgIcon>
  );
}

function PinIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

function InfoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.042,3a9.042,9.042,0,1,0,9.042,9.042A9.045,9.045,0,0,0,12.042,3Zm.9,13.562H11.137V11.137h1.808Zm0-7.233H11.137V7.521h1.808Z" />
    </SvgIcon>
  );
}

function EarthIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.991,1.928a7.421,7.421,0,1,0,7.421,7.421A7.421,7.421,0,0,0,9.991,1.928Zm0,13.914a6.471,6.471,0,0,1-2.569-.528l3.379-3.8a.464.464,0,0,0,.117-.308V9.812a.464.464,0,0,0-.464-.464c-1.638,0-3.365-1.7-3.382-1.719a.464.464,0,0,0-.328-.136H4.89a.464.464,0,0,0-.464.464V10.74a.464.464,0,0,0,.256.415l1.6.8v2.723A6.5,6.5,0,0,1,4.123,6.566H5.817a.464.464,0,0,0,.328-.136L8,4.575a.464.464,0,0,0,.136-.328V3.125a6.511,6.511,0,0,1,4.7.386c-.06.051-.118.1-.175.16a2.783,2.783,0,0,0,1.966,4.751q.069,0,.138,0a10.891,10.891,0,0,1-.122,5.4.462.462,0,0,0-.012.076,6.473,6.473,0,0,1-4.64,1.952Z" />
    </SvgIcon>
  );
}

export default withWidth()(EMGroupDetails);

import React, { useState, useRef, useEffect } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "./styles.css";

import "react-image-crop/dist/ReactCrop.css";
import {
  Card,
  Grid,
  Button,
  Hidden,
  Modal,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { findLast } from "lodash";

const useStyles = makeStyles((theme) => ({
  card: {
    card: {
      padding: "0px 36px",
      width: "100%",
      height: "100%",
      boxShadow: "0px 3px 6px #00000029",
    },
  },
}));

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function EMImageCropper({
  changeHandler,
  id,
  accept,
  fileSize,
  supportedFormats,
  maxFileSize,
  aspectRatio,
  onCancel,
  entityId,
  manageBrowse
}) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [croppedImage, setCroppedImage] = useState(null);
  const [error, setError] = React.useState();
  const [fileSelectedName, setFileSelectedName] = React.useState(undefined);
  const [open, setOpen] = useState(false);
  const uiData = useSelector(data => data.ui || {})

  useEffect(() => {
    
    if (uiData["messages"]) {
      let refObj
        refObj = findLast(uiData["messages"], {
        message: "LOGO filename should not be greater than 100 characters",
      });
      if(refObj == undefined || refObj == null){
        refObj = findLast(uiData["messages"], {
        message: "Banner filename should not be greater than 100 characters",
      }); 
      }
      if (refObj && refObj.type === "error") {
        setFileSelectedName(undefined)
        setImgSrc("")
        setCrop()
        setCompletedCrop()
        setCroppedImage(null)
        setError()
      }
    }
  }, [uiData]);

  useEffect(() => {
    if(onCancel==undefined){
      setFileSelectedName(undefined)
      setImgSrc("")
      setCrop()
      setCompletedCrop()
      setCroppedImage(null)
      setError()
    }
  },[onCancel])

  function onSelectFile(e) {
    
    if (e.target.files && e.target.files.length > 0) {
      if (
        supportedFormats.includes(e.target.files[0] && e.target.files[0].type)
      ) {
        if (e.target.files[0] && e.target.files[0].size <= fileSize) {
          let fileName = e.target.files[0].name;
          setError("");
          setFileSelectedName(fileName);
          setCrop(undefined); // Makes crop preview update between images.
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            setImgSrc(reader.result.toString() || "")
          );
          reader.readAsDataURL(e.target.files[0]);
          setOpen(true);
        } else {
          setError(
            "Selected File : " + e.target.files[0] &&
              e.target.files[0].name + " should be less than " + maxFileSize
          );
          setFileSelectedName(null);
          setOpen(false);
        }
      } else {
        setError(
          "Selected File : " + e.target.files &&
            e.target.files[0] &&
            e.target.files[0].name + " is not supported"
        );
        setFileSelectedName(null);
        setOpen(false);
      }
    }
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    let croppedImage = new File([u8arr], filename, { type: mime });
    setCroppedImage(croppedImage);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        );
        getCroppedImg(imgRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  function getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, fileSelectedName);
      };
    });
  }

  function onImageLoad(e) {
    if (aspectRatio) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectRatio));
    }
  }

  const classes = useStyles();

  return (
    <div>
      <form>
        <Grid style={{ marginBottom: 20 }} container spacing={2}>
          <Grid xs={8}>
              {fileSelectedName != null || fileSelectedName !== undefined ? (
                <Typography style={{
                  display: "-webkit-box",
                  boxOrient: "vertical",
                  lineClamp: 2,
                  wordBreak: "break-word",
                  overflow: "hidden",
                }}>File Selected - {fileSelectedName}</Typography>
              ) : (
                <Typography>Choose a file...</Typography>
              )}

          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <input
              style={{ display: "none" }}
              id={"contained-button-file " + id}
              type="file"
              accept={accept}
              onChange={(e) => {
                onSelectFile(e)
                e.target.value=null
              }}
              disabled={manageBrowse && !entityId}
            />
            <label htmlFor={"contained-button-file " + id}>
              <Hidden smDown mdDown>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                 disabled={manageBrowse && !entityId}
                  // style={{ marginLeft: "2rem" }}
                >
                  Browse
                </Button>
              </Hidden>
              <Hidden lgUp mdUP>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ marginTop: "1rem" }}
                >
                  Browse
                </Button>
              </Hidden>
            </label>
          </Grid>
        </Grid>
        <p style={{ color: "red" }}>{error}</p>
      </form>
      <Modal
        style={{
          // display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "80%",
          marginLeft: "10%",
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setCompletedCrop();
          setFileSelectedName(undefined);
          setCrop();
          setImgSrc();
        }}
      >
        <Paper
          style={{
            padding: 30,
            // paddingBottom: 5,
            maxHeight: window.screen.height / 1.2,
            width: "100%",
            overflow: "auto",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ padding: 5 }}
          >
            {Boolean(imgSrc) && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                  setCompletedCrop(c);
                }}
                maxWidth={window.screen.width / 1.2}
                maxHeight={window.screen.height / 1.4}
                aspect={aspectRatio}
                locked
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Box>
          <Grid container direction="row" spacing={2}>
            <Grid
              item
              xs={6}
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ padding: 10 }}
                onClick={() => {
                  changeHandler(croppedImage);
                  setOpen(false);
                }}
              >
                Save
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                style={{ padding: 10 }}
                onClick={() => {
                  setOpen(false);
                  setCompletedCrop();
                  setFileSelectedName(undefined);
                  setCrop();
                  setImgSrc();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography style={{ fontWeight: "bolder", marginBottom: "8px" }}>
              Preview:
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              {Boolean(completedCrop) && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Modal>
      <div></div>
    </div>
  );
}

import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMChannelDetails from "./EMChannelDetail";
import EMChannelDetailsForNew from "./EMChannelDetailsforNew";
import EMPage from "../../../../../components/commans/EMPage";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import EMPost from "../../../../../components/user/EMPost";
import EMChannelsPreview from "../../../../../components/user/EMChannelsPreview";
import { useSelector, useDispatch } from "react-redux";
import EMDropdown from "../../../../../components/user/EMCategoriesDropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  EMDoLikeDislikePinUnpinPostAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoGetHashTagListsAction,
  EMDoGetCategoryListAction,
  EMDoLikeDislikePostAction,
} from "../../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  SHOW_NEXT_POSTS,
  POST_CATEGORY_SELECTED_DATA,
  CLEAR_POST_FROM_STATE,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
} from "../../../../../redux/constants/UserPlatform/index";
import { GROUP_EDIT } from "../../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetPrivacyList } from "../../../../../redux/actions/EMNetworkAdminActions";
import { debounce, findLast } from "lodash";
import { SHOW_MESSAGE } from "../../../../../redux/constants";
import EMEvent from "../../../../../components/user/EMEvent";
import {
  EMDoActionForChannelAction,
  EMDoGetChannelDetailsAction,
  EMDoGetCategoryFilteredPostForChannelListAction,
  EMDoGetChannelPostsAction,
  EMDoGetChannelsListAction,
  EMDoGetEmailNotificationChannelAction,
} from "../../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import {
  CHANNEL_EDIT,
  SHOW_INFO_OF_CHANNEL,
} from "../../../../../redux/constants/UserPlatform/EMChannelConstant";
import InfiniteScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../../components/navigation/EMSideBar";
import EMPoll from "../../../../../components/user/EMPoll";
import EMQuiz from "../../../../../components/user/EMQuiz";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../../../redux/actions/EMAuthActions";
import { EMDoGetChannelAdminChannelDetailsByIdAction } from "../../../../../redux/actions/EMChannelAdminActions";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../../../redux/constants/UserPlatform/EMNavigationConstant";

const useStyles = makeStyles((theme) => ({}));

export default function EMActivityFeedPage(props) {
  const data = useSelector((state) => state.channels || {});
  const userData = useSelector((state) => state.auth || {});
  const uiData = useSelector((state) => state.ui || {});
  const groupData = useSelector((state) => state.groupAdmin || {});
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [limit, setLimit] = useState(1);
  const [limitForCategories, setLimitForCategories] = useState(1);
  const selectedCategory = useSelector((state) => state.home.categorySelected);
  const selectedDataForDropdown = useSelector((state) => state.home.categories);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const location = useLocation();

  useEffect(() => {
    let channelId = location.state && location.state.ChannelId;

    dispatch(
      EMDoGetChannelDetailsAction({
        id: channelId || window.sessionStorage.getItem("ChannelId"),
      })
    );
    dispatch({
      type: SHOW_INFO_OF_CHANNEL,
      payload: false,
    });
    dispatch({
      type: SHOW_NEXT_POSTS,
      payload: {
        nextPosts: 1,
      },
    });
    // dispatch(EMDoGetPostSharedWithGroupsListAction());
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
    dispatch(EMDoGetChannelsListAction());
    setLimit(1);
    setLimitForCategories(1);
    dispatch(EMDoGetPrivacyList());

    // setTimeout(() => {
    //   dispatch(
    //     EMDoGetChannelPostsAction({
    //       id: channelId || window.sessionStorage.getItem("ChannelId"),
    //       page: 1,
    //       userData,
    //     })
    //   );
    // }, 100);
  }, [location]);

  useEffect(() => {
    dispatch(
      EMGetRolesAndPermissionsBasedOnEntityAction({
        entity: "channel",
        entityId: window.sessionStorage.getItem("ChannelId"),
      })
    );
  }, []);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "update_channel_settings",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "update_channel_settings_done";
        dispatch(
          EMDoGetChannelDetailsAction({
            id:
              data.singleChannel.id ||
              window.sessionStorage.getItem("ChannelId"),
          })
        );
        dispatch({
          type: CHANNEL_EDIT,
          payload: false,
        });
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        setLimit(1);
        dispatch(
          EMDoGetChannelPostsAction({
            id: window.sessionStorage.getItem("ChannelId"),
            page: limit,
            userData,
          })
        );
        refObj.key = "user_create_poll_post_event_quiz_success";
      }
    }
  }, [uiData]);

  const sendQuery = useCallback(async () => {
    if (typeof data.categorySelected === "string") {
      setTimeout(() => {
        dispatch(
          EMDoGetChannelPostsAction({
            id: window.sessionStorage.getItem("ChannelId"),
            page: limit,
            userData,
          })
        );
      }, 100);
    } else if (typeof data.categorySelected !== "string") {
      dispatch(
        EMDoGetCategoryFilteredPostForChannelListAction({
          selectedDataOption: data.categorySelected.category_name,
          page: limitForCategories,
          userData,
          id: window.sessionStorage.getItem("ChannelId"),
        })
      );
    }
  }, [limit, limitForCategories]);

  useEffect(() => {
    if (typeof data.categorySelected === "string") {
      sendQuery(limit);
    } else if (typeof data.categorySelected !== "string") {
      sendQuery(limitForCategories);
    }
  }, [sendQuery, limit, limitForCategories]);

  const handleObserver = useCallback(() => {
    if (typeof data.categorySelected === "string" && data.next !== null) {
      setLimit((prev) => prev + 1);
    } else if (
      typeof data.categorySelected !== "string" &&
      data.next !== null
    ) {
      setLimitForCategories((prev) => prev + 1);
    }
  }, [limitForCategories, limit, data.categorySelected, data.next]);

  return (
    <InfiniteScroll
      dataLength={data.posts.length}
      hasMore={true}
      next={handleObserver}
    >
      <EMPage
        title="Channel Detail"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "4%" : "2%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "4%" : "2%",
          paddingBottom: "2%",
          backgroundColor: "#e7dadc",
        }}
      >
        <div ref={ref}>
          <Grid container direction="row" alignItems="flexStart" spacing={3}>
            {matchesMediumScreen ? (
              <Grid item xs={matchesLargeScreen ? 2 : 3}>
                <EMSideBar />
              </Grid>
            ) : null}
            {data.singleChannel && data.singleChannel.is_channel_user ? (
              <Grid
                item
                xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
                style={{ zIndex: 1 }}
              >
                <EMChannelDetails
                  onInfoClick={() => {
                    dispatch({
                      type: SHOW_INFO_OF_CHANNEL,
                      payload: !data.channelInfo,
                    });
                  }}
                  onStarClick={() => {
                    let actions;
                    data.singleChannel.is_fav
                      ? (actions = `remove-favorite`)
                      : (actions = `favorite`);
                    dispatch(
                      EMDoActionForChannelAction({
                        id: data.singleChannel.id
                          ? data.singleChannel.id
                          : window.sessionStorage.getItem("ChannelId"),
                        myaction: actions,
                        // postIndex:key,
                        route: "channel_details",
                      })
                    );
                  }}
                  onNotificationClick={() => {
                    let actions;
                    data.singleChannel.is_subscribed
                      ? (actions = `unsubscribe`)
                      : (actions = `subscribe`);
                    dispatch(
                      EMDoActionForChannelAction({
                        id: data.singleChannel.id
                          ? data.singleChannel.id
                          : window.sessionStorage.getItem("ChannelId"),
                        myaction: actions,
                        // postIndex:key,
                        route: "channel_details",
                      })
                    );
                  }}
                  onEmailNotificationClick={(e) => {
                    dispatch(
                      EMDoGetEmailNotificationChannelAction({
                        id: data.singleChannel.id
                          ? data.singleChannel.id
                          : window.sessionStorage.getItem("ChannelId"),
                        is_email_notification:
                          !data.singleChannel.email_notification,
                        route: "email_notification",
                      })
                    );
                  }}
                  channelBanner={
                    data.singleChannel.banner_file
                      ? data.singleChannel.banner_file
                      : null
                  }
                  channelDescription={
                    data.singleChannel.tagline
                      ? data.singleChannel.tagline
                      : null
                  }
                  channelAdditionalInfo={
                    data.singleChannel.is_channel_user&& data.singleChannel.additional_info
                      ? data.singleChannel.additional_info
                      : null
                  }
                  channelLogo={
                    data.singleChannel.logo_file
                      ? data.singleChannel.logo_file
                      : null
                  }
                  channelName={
                    data.singleChannel.channel_name
                      ? data.singleChannel.channel_name
                      : null
                  }
                  channelMembers={
                    data.singleChannel.number_of_channel_users
                      ? data.singleChannel.number_of_channel_users
                      : null
                  }
                  channelOrganizations={
                    data.singleChannel.organizations
                      ? data.singleChannel.organizations
                      : null
                  }
                  groupName={data.singleChannel.group_name}
                  channelMission={
                    (data.singleChannel?.is_channel_user ||
                      data.singleChannel?.privacy_type_name === "Public" ||
                      data.singleChannel?.privacy_type_name === "Restricted") &&
                    data.singleChannel &&
                    data.singleChannel.mission_stmt
                      ? data.singleChannel.mission_stmt
                      : null
                  }
                  channelVision={
                    (data.singleChannel?.is_channel_user ||
                      data.singleChannel?.privacy_type_name === "Public" ||
                      data.singleChannel?.privacy_type_name === "Restricted") &&
                    data.singleChannel &&
                    data.singleChannel.vision_stmt
                      ? data.singleChannel.vision_stmt
                      : null
                  }
                  is_subscribed={
                    data.singleChannel.is_subscribed
                      ? data.singleChannel.is_subscribed
                      : null
                  }
                  is_fav={
                    data.singleChannel.is_fav ? data.singleChannel.is_fav : null
                  }
                  is_email_notification={
                    data.singleChannel.email_notification
                      ? data.singleChannel.email_notification
                      : null
                  }
                  channelPrivacy={
                    data.singleChannel.privacy_type_name
                      ? data.singleChannel.privacy_type_name
                      : null
                  }
                  channelAdmins={
                    data.singleChannel.admins ? data.singleChannel.admins : null
                  }
                  // onMemberClick={() => {
                  //   navigation("/users/groups/members");
                  // }}
                  isChannelUser={true}
                />
                { data.channelInfo ? null : (
                  <div>
                    <Grid
                      container
                      direction="row"
                      // justify="center"
                      alignItems="center"
                      style={{ marginTop: "2%" }}
                    >
                      <Grid item md={8} sm={6} xs={4}>
                        <hr />
                      </Grid>
                      <Grid item md={4} sm={6} xs={8}>
                       {data.singleChannel?.is_channel_user && <EMDropdown
                          handleChange={(event) => {
                            setLimitForCategories(1);
                            let selectedDataOption =
                              event.target.value == "Images & Videos"
                                ? "Media"
                                : event.target.value;
                            dispatch({
                              type: POST_CATEGORY_SELECTED_DATA,
                              payload: selectedDataOption,
                            });
                            dispatch(
                              EMDoGetCategoryFilteredPostForChannelListAction({
                                selectedDataOption: selectedDataOption,
                                page: limitForCategories,
                                userData,
                                id: data.singleChannel.id,
                              })
                            );
                          }}
                          deleteHandler={(event) => {
                            setLimit(1);
                            let selectedDataOption =
                              event.target.value == "Images & Videos"
                                ? "Media"
                                : event.target.value;
                            dispatch({
                              type: POST_CATEGORY_SELECTED_DATA,
                              payload: selectedDataOption,
                            });
                            dispatch({
                              type: CLEAR_POST_FROM_STATE,
                              payload: [],
                            });
                            dispatch(
                              EMDoGetChannelPostsAction({
                                id: data.singleChannel.id,
                                userData,
                                page: limit,
                              })
                            );
                          }}
                          selectedCategory={selectedCategory}
                          selectedDataForDropdown={selectedDataForDropdown}
                        />}
                      </Grid>
                    </Grid>
                    <Box style={{ marginTop: "2%" }}>
                      {data.singleChannel?.is_channel_user && data.posts.map((post, id) =>
                        post.is_event ? (
                          <EMEvent
                            permission={true}
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            likeHandler={() => {
                              let actions = [];
                              post.liked
                                ? actions.push(`dislike`)
                                : actions.push(`like`);

                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };

                              dispatch({
                                type: CHANGE_LIKE_STATUS_OF_POST,
                                payload: {
                                  id: post.id,
                                  isLiked: post.liked ? false : true,
                                },
                              });
                              dispatch({
                                type: CHANGE_LIKE_BUTTON_STATE,
                                payload: {
                                  id: post.id,
                                  isDisabled: true,
                                },
                              });

                              dispatch(
                                EMDoLikeDislikePostAction({
                                  queryData: queryData,
                                })
                              );
                            }}
                          />
                        ) : post.is_poll ? (
                          <EMPoll
                            id={window.sessionStorage.getItem("ChannelId")}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            permission={true}
                            post={post}
                          />
                        ) : post.is_quiz ? (
                          <EMQuiz
                            quizFromQuiz={false}
                            id={window.sessionStorage.getItem("ChannelId")}
                            permission={true}
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                          />
                        ) : (
                          <EMPost
                            id={window.sessionStorage.getItem("ChannelId")}
                            permission={true}
                            usedIn="channels"
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            likeHandler={() => {
                              let actions = [];
                              post.liked
                                ? actions.push(`dislike`)
                                : actions.push(`like`);

                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };

                              dispatch({
                                type: CHANGE_LIKE_STATUS_OF_POST,
                                payload: {
                                  id: post.id,
                                  isLiked: post.liked ? false : true,
                                },
                              });
                              dispatch({
                                type: CHANGE_LIKE_BUTTON_STATE,
                                payload: {
                                  id: post.id,
                                  isDisabled: true,
                                },
                              });

                              dispatch(
                                EMDoLikeDislikePostAction({
                                  queryData: queryData,
                                })
                              );
                            }}
                          />
                        )
                      )}
                    </Box>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid
                item
                xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
                style={{ zIndex: 1 }}
              >
                {data.singleChannel.privacy_type_name === "Private" ? (
                  <EMChannelDetailsForNew
                    channelBanner={
                      data.singleChannel.banner_file
                        ? data.singleChannel.banner_file
                        : null
                    }
                    channelDescription={
                      data.singleChannel.tagline
                        ? data.singleChannel.tagline
                        : null
                    }
                    channelAdditionalInfo={
                      data.singleChannel.is_channel_user&& data.singleChannel.additional_info
                        ? data.singleChannel.additional_info
                        : null
                    }
                    channelMission={
                      (data.singleChannel?.is_channel_user ||
                        data.singleChannel?.privacy_type_name === "Public" ||
                        data.singleChannel?.privacy_type_name ===
                          "Restricted") &&
                      data.singleChannel.mission_stmt
                        ? data.singleChannel.mission_stmt
                        : null
                    }
                    channelVision={
                      (data.singleChannel?.is_channel_user ||
                        data.singleChannel?.privacy_type_name === "Public" ||
                        data.singleChannel?.privacy_type_name ===
                          "Restricted") &&
                      data.singleChannel.vision_stmt
                        ? data.singleChannel.vision_stmt
                        : null
                    }
                    channelLogo={
                      data.singleChannel.logo_file
                        ? data.singleChannel.logo_file
                        : null
                    }
                    channelName={
                      data.singleChannel.channel_name
                        ? data.singleChannel.channel_name
                        : null
                    }
                    channelMembers={
                      data.singleChannel.number_of_channel_users
                        ? data.singleChannel.number_of_channel_users
                        : null
                    }
                    channelOrganizations={
                      data.singleChannel.organizations
                        ? data.singleChannel.organizations
                        : null
                    }
                    isPinned={
                      data.singleChannel.is_active
                        ? data.singleChannel.is_active
                        : null
                    }
                    channelPrivacy={
                      data.singleChannel.privacy_type_name
                        ? data.singleChannel.privacy_type_name
                        : null
                    }
                    // onMemberClick={() => {
                    //   navigation("members");
                    // }}
                    channelAdmins={
                      data.singleChannel.admins
                        ? data.singleChannel.admins
                        : null
                    }
                  />
                ) : null}

                {data.singleChannel.privacy_type_name === "Public" ? (
                  <div>
                    <EMChannelDetails
                      isChannelUser={false}
                      onInfoClick={() => {
                        dispatch({
                          type: SHOW_INFO_OF_CHANNEL,
                          payload: !data.channelInfo,
                        });
                      }}
                      onStarClick={() => {
                        let actions;
                        data.singleChannel.is_fav
                          ? (actions = `remove-favorite`)
                          : (actions = `favorite`);
                        dispatch(
                          EMDoActionForChannelAction({
                            id: data.singleChannel.id
                              ? data.singleChannel.id
                              : window.sessionStorage.getItem("ChannelId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "channel_details",
                          })
                        );
                      }}
                      onNotificationClick={() => {
                        let actions;
                        data.singleChannel.is_subscribed
                          ? (actions = `unsubscribe`)
                          : (actions = `subscribe`);
                        dispatch(
                          EMDoActionForChannelAction({
                            id: data.singleChannel.id
                              ? data.singleChannel.id
                              : window.sessionStorage.getItem("ChannelId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "channel_details",
                          })
                        );
                      }}
                      channelBanner={
                        data.singleChannel.banner_file
                          ? data.singleChannel.banner_file
                          : null
                      }
                      channelDescription={
                        data.singleChannel.tagline
                          ? data.singleChannel.tagline
                          : null
                      }
                      channelAdditionalInfo={
                        data.singleChannel.is_channel_user&& data.singleChannel.additional_info
                          ? data.singleChannel.additional_info
                          : null
                      }
                      channelLogo={
                        data.singleChannel.logo_file
                          ? data.singleChannel.logo_file
                          : null
                      }
                      channelName={
                        data.singleChannel.channel_name
                          ? data.singleChannel.channel_name
                          : null
                      }
                      channelMembers={
                        data.singleChannel.number_of_channel_users
                          ? data.singleChannel.number_of_channel_users
                          : null
                      }
                      channelOrganizations={
                        data.singleChannel.organizations
                          ? data.singleChannel.organizations
                          : null
                      }
                      channelMission={
                        (data.singleChannel?.is_channel_user ||
                          data.singleChannel?.privacy_type_name === "Public" ||
                          data.singleChannel?.privacy_type_name ===
                            "Restricted") &&
                        data.singleChannel &&
                        data.singleChannel.mission_stmt
                          ? data.singleChannel.mission_stmt
                          : null
                      }
                      channelVision={
                        (data.singleChannel?.is_channel_user ||
                          data.singleChannel?.privacy_type_name === "Public" ||
                          data.singleChannel?.privacy_type_name ===
                            "Restricted") &&
                        data.singleChannel &&
                        data.singleChannel.vision_stmt
                          ? data.singleChannel.vision_stmt
                          : null
                      }
                      is_subscribed={
                        data.singleChannel.is_subscribed
                          ? data.singleChannel.is_subscribed
                          : null
                      }
                      is_fav={
                        data.singleChannel.is_fav
                          ? data.singleChannel.is_fav
                          : null
                      }
                      channelPrivacy={
                        data.singleChannel.privacy_type_name
                          ? data.singleChannel.privacy_type_name
                          : null
                      }
                      channelAdmins={
                        data.singleChannel.admins
                          ? data.singleChannel.admins
                          : null
                      }
                      // onMemberClick={() => {
                      //   navigation("/users/groups/members");
                      // }}
                    />
                    {data.channelInfo ? null : (
                      <div>
                        <Grid
                          container
                          direction="row"
                          // spacing={2}
                          // justify="center"
                          alignItems="center"
                          style={{ marginTop: "2%" }}
                        >
                          <Grid item md={8} sm={6} xs={4}>
                            <hr />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={8}
                            // style={{
                            //   maxWidth: "22%",
                            //   flexGrow: "0",
                            //   flexBasis: "25%",
                            // }}
                          >
                           {data.singleChannel?.is_channel_user && <EMDropdown
                              handleChange={(event) => {
                                setLimitForCategories(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch(
                                  EMDoGetCategoryFilteredPostForChannelListAction(
                                    {
                                      selectedDataOption: selectedDataOption,
                                      page: limitForCategories,
                                      userData,
                                      id: data.singleChannel.id,
                                    }
                                  )
                                );
                              }}
                              deleteHandler={(event) => {
                                setLimit(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch({
                                  type: CLEAR_POST_FROM_STATE,
                                  payload: [],
                                });
                                dispatch(
                                  EMDoGetChannelPostsAction({
                                    id: data.singleChannel.id,
                                    userData,
                                    page: 1,
                                  })
                                );
                              }}
                              selectedCategory={selectedCategory}
                              selectedDataForDropdown={selectedDataForDropdown}
                            />}
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "2%" }}>
                          {data.singleChannel?.is_channel_user && data.posts.map((post, id) =>
                            post.is_event ? (
                              <EMEvent
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                likeHandler={() => {
                                  let actions = [];
                                  post.liked
                                    ? actions.push(`dislike`)
                                    : actions.push(`like`);

                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };

                                  dispatch({
                                    type: CHANGE_LIKE_STATUS_OF_POST,
                                    payload: {
                                      id: post.id,
                                      isLiked: post.liked ? false : true,
                                    },
                                  });
                                  dispatch({
                                    type: CHANGE_LIKE_BUTTON_STATE,
                                    payload: {
                                      id: post.id,
                                      isDisabled: true,
                                    },
                                  });

                                  dispatch(
                                    EMDoLikeDislikePostAction({
                                      queryData: queryData,
                                    })
                                  );
                                }}
                              />
                            ) : post.is_poll ? (
                              <EMPoll
                                id={window.sessionStorage.getItem("ChannelId")}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                permission={true}
                                post={post}
                              />
                            ) : post.is_quiz ? (
                              <EMQuiz
                                quizFromQuiz={false}
                                id={window.sessionStorage.getItem("ChannelId")}
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                              />
                            ) : (
                              <EMPost
                                id={window.sessionStorage.getItem("ChannelId")}
                                usedIn="channels"
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                likeHandler={() => {
                                  let actions = [];
                                  post.liked
                                    ? actions.push(`dislike`)
                                    : actions.push(`like`);

                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };

                                  dispatch({
                                    type: CHANGE_LIKE_STATUS_OF_POST,
                                    payload: {
                                      id: post.id,
                                      isLiked: post.liked ? false : true,
                                    },
                                  });
                                  dispatch({
                                    type: CHANGE_LIKE_BUTTON_STATE,
                                    payload: {
                                      id: post.id,
                                      isDisabled: true,
                                    },
                                  });

                                  dispatch(
                                    EMDoLikeDislikePostAction({
                                      queryData: queryData,
                                    })
                                  );
                                }}
                              />
                            )
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}

                {data.singleChannel.privacy_type_name === "Restricted" ? (
                  <div>
                    <EMChannelDetails
                      isChannelUser={false}
                      onInfoClick={() => {
                        dispatch({
                          type: SHOW_INFO_OF_CHANNEL,
                          payload: !data.channelInfo,
                        });
                      }}
                      onStarClick={() => {
                        let actions;
                        data.singleChannel.is_fav
                          ? (actions = `remove-favorite`)
                          : (actions = `favorite`);
                        dispatch(
                          EMDoActionForChannelAction({
                            id: data.singleChannel.id
                              ? data.singleChannel.id
                              : window.sessionStorage.getItem("ChannelId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "channel_details",
                          })
                        );
                      }}
                      onNotificationClick={() => {
                        let actions;
                        data.singleChannel.is_subscribed
                          ? (actions = `unsubscribe`)
                          : (actions = `subscribe`);
                        dispatch(
                          EMDoActionForChannelAction({
                            id: data.singleChannel.id
                              ? data.singleChannel.id
                              : window.sessionStorage.getItem("ChannelId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "channel_details",
                          })
                        );
                      }}
                      channelBanner={
                        data.singleChannel.banner_file
                          ? data.singleChannel.banner_file
                          : null
                      }
                      channelDescription={
                        data.singleChannel.tagline
                          ? data.singleChannel.tagline
                          : null
                      }
                      channelAdditionalInfo={
                        data.singleChannel.is_channel_user&& data.singleChannel.additional_info
                          ? data.singleChannel.additional_info
                          : null
                      }
                      channelLogo={
                        data.singleChannel.logo_file
                          ? data.singleChannel.logo_file
                          : null
                      }
                      channelName={
                        data.singleChannel.channel_name
                          ? data.singleChannel.channel_name
                          : null
                      }
                      channelMembers={
                        data.singleChannel.number_of_channel_users
                          ? data.singleChannel.number_of_channel_users
                          : null
                      }
                      channelOrganizations={
                        data.singleChannel.organizations
                          ? data.singleChannel.organizations
                          : null
                      }
                      channelMission={
                        (data.singleChannel?.is_channel_user ||
                          data.singleChannel?.privacy_type_name === "Public" ||
                          data.singleChannel?.privacy_type_name ===
                            "Restricted") &&
                        data.singleChannel &&
                        data.singleChannel.mission_stmt
                          ? data.singleChannel.mission_stmt
                          : null
                      }
                      channelVision={
                        (data.singleChannel?.is_channel_user ||
                          data.singleChannel?.privacy_type_name === "Public" ||
                          data.singleChannel?.privacy_type_name ===
                            "Restricted") &&
                        data.singleChannel &&
                        data.singleChannel.vision_stmt
                          ? data.singleChannel.vision_stmt
                          : null
                      }
                      is_subscribed={
                        data.singleChannel.is_subscribed
                          ? data.singleChannel.is_subscribed
                          : null
                      }
                      is_fav={
                        data.singleChannel.is_fav
                          ? data.singleChannel.is_fav
                          : null
                      }
                      channelPrivacy={
                        data.singleChannel.privacy_type_name
                          ? data.singleChannel.privacy_type_name
                          : null
                      }
                      channelAdmins={
                        data.singleChannel.admins
                          ? data.singleChannel.admins
                          : null
                      }
                      // onMemberClick={() => {
                      //   navigation("/users/groups/members");
                      // }}
                    />
                    {data.channelInfo ? null : (
                      <div>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          // justify="center"
                          alignItems="center"
                          style={{ marginTop: "2%" }}
                        >
                          <Grid item md={8} sm={6} xs={4}>
                            <hr />
                          </Grid>
                          <Grid item md={4} sm={6} xs={8}>
                         { data.singleChannel?.is_channel_user &&  <EMDropdown
                              handleChange={(event) => {
                                setLimitForCategories(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch(
                                  EMDoGetCategoryFilteredPostForChannelListAction(
                                    {
                                      selectedDataOption: selectedDataOption,
                                      page: limitForCategories,
                                      userData,
                                      id: data.singleChannel.id,
                                    }
                                  )
                                );
                              }}
                              deleteHandler={(event) => {
                                setLimit(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch({
                                  type: CLEAR_POST_FROM_STATE,
                                  payload: [],
                                });
                                dispatch(
                                  EMDoGetChannelPostsAction({
                                    id: data.singleChannel.id,
                                    userData,
                                    page: 1,
                                  })
                                );
                              }}
                              selectedCategory={selectedCategory}
                              selectedDataForDropdown={selectedDataForDropdown}
                            />}
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "2%" }}>
                          {data.singleChannel?.is_channel_user && data.posts.map((post, id) =>
                            post.is_event ? (
                              <EMEvent
                                permission={false}
                                post={post}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of Channel",
                                    },
                                  });
                                }}
                                likeHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of Channel",
                                    },
                                  });
                                }}
                              />
                            ) : post.is_poll ? (
                              <EMPoll
                                id={window.sessionStorage.getItem("ChannelId")}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of channel",
                                    },
                                  });
                                }}
                                permisssion={false}
                                post={post}
                              />
                            ) : post.is_quiz ? (
                              <EMQuiz
                                quizFromQuiz={false}
                                id={window.sessionStorage.getItem("ChannelId")}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of channel",
                                    },
                                  });
                                }}
                                permisssion={false}
                                post={post}
                              />
                            ) : (
                              <EMPost
                                id={window.sessionStorage.getItem("ChannelId")}
                                usedIn="channels"
                                permission={false}
                                post={post}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of Channel",
                                    },
                                  });
                                }}
                                likeHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of Channel",
                                    },
                                  });
                                }}
                              />
                            )
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Grid>
            )}
            {matchesLargeScreen ? (
              <Grid item xs={3} style={{ marginBottom: "2%" }}>
                <EMChannelsPreview
                  data={data.channelList}
                  route="groups"
                  usedIn="channels"
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </EMPage>
    </InfiniteScroll>
  );
}

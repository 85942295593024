import axios from "axios";

const EMDoGetNetworkAdminUsersList = queryData => {
  let url = "";
   
  if (queryData) {
    url = `/network/users/${queryData}`;
  } else {
    url = `/network/users/`
  }
  return axios.get(url);
}

const EMDoGetRolesList = ({  contextData }) => {
  let url = `/network/roles/?context_type=${contextData.contextType}&context=${contextData.context}`;
  return axios.get(url);
};


const EMDoUpdateNetworkAdmin = ({ data, contextData }) => {
  
  let url = `/network/users/${data.user_id}/?context_type=${contextData.contextType}&context=${contextData.context}`;
  // let url = `/network/users/?context_type=${contextData.contextType}&context=${contextData.context}`;
 return axios.put(url, { ...data });
};

const EMDoCreateNetworkAdmin =({ data, contextData }) => {
   
  let url = `/network/users/?context_type=${contextData.contextType}&context=${contextData.context}&email_template=${data.template}`;
  return axios.post(url, { ...data });
};

const EMDoExportNetworkAdminUsers = ({  contextData }) => {
  let url = `/network/export/?context_type=${contextData.contextType}&context=${contextData.context}`;
  // let url=`/network/export/`
  return axios.get(url);
};

/* Bulk Upload Functionality */
const EMDoBulkUploadUsersList = ({ data, contextData })=>{
  
  const url = `/commans/bulk_upload_excel_data/?context_type=${contextData.contextType}&context=${contextData.context}&email_template=${contextData.email_template}`;
  const fileData = new FormData()
  fileData.append("file",data[0])
  return axios.post(url,fileData,{
    headers : {
      'Content-Type': 'multipart/form-data'
    }
  })
}

//resend invitation functionality

const EMDoGetResendInviteUsersList = queryData =>{
  
  let url ="";
  if (queryData) {
    url = `/invitations/resend-invitation/${queryData}`;
  } else {
    url = `/invitations/resend-invitation/`
  }
  return axios.get(url);
}

const EMDoResendInviteToUsers = ({ data, contextData }) => {
  let url = `/invitations/resend-invitation/?context_type=${contextData.contextType}&context=${contextData.context}&email_template=${contextData.emailTemplate}`;
  return axios.post(url, { ...data });
};

/* import Users Functionality */

const EMDoGetAllImportUsersList = queryData =>{
  let url ="";
  if (queryData) {
    url = `/network/importUsers/${queryData}`;
  } else {
    url = `/network/importUsers/`
  }
  return axios.get(url);
}

const EMDoImportAllSelectedUsersList = ({ data, contextData, email_template }) => {
  let url = `/network/importUsers/?context_type=${contextData.contextType}&context=${contextData.context}&email_template=${email_template}`;
  return axios.post(url, { ...data });
};

const EMDoGetFilterByGroupDropdownValues = ({ contextData }) => {
  let url = `/network/filter_by_group/?context_type=${contextData.contextType}&context=${contextData.context}`;
  return axios.get(url);
}

const EMDoGetFilterByGroupList = ({ queryData }) => {
  let url = `/network/filter_users_by_group_id/${queryData}`
  return axios.get(url);
}
const EMDoGetFilterByGroupListById = ({ contextData,data }) => {
  
  let url = `/network/filter_by_group/?context_type=${contextData.contextType}&context=${contextData.context}`
  // return axios.post(url,{...data});
  return axios.post(url, {...data}, {
    headers: {
        'Content-Type': 'application/json'
    }
})
}

/* email Template Functionality */

const EMDoGetNetworkAdminGroupList = queryData => {
    let url = "";
    if (queryData) {
      url = `/group/${queryData}`;
    } 
    return axios.get(url);
  };

const EMDoUpdateNetworkAdminGroup = ({groupData,queryData}) => {
    let url = "";
    if (queryData) {
      url = `/group/${groupData.id}/${queryData}`;
    } else {
      url = `/group/${groupData.id}/`;
    }
    return axios.put(url, { ...groupData });
  };
  
const EMDoCreateNetworkAdminGroup = ({groupData,queryData}) => {
    let url = "";
    if (queryData) {
      url = `/group/${queryData}`;
    } else {
      url = `/group/`;
    }
    return axios.post(url, { ...groupData });
  };

const EMDoGetNetworkAdminChannelList = queryData => {
    let url = "";
    if (queryData) {
      url = `/channel/${queryData}`;
    } 
    return axios.get(url);
  };

const EMDoUpdateNetworkAdminChannel = channelData => {
    let url = `/channel/${channelData.id}/`;
    return axios.put(url, { ...channelData });
  };
  
const EMDoCreateNetworkAdminChannel = channelData => {
    let url = `/channel/`;
    return axios.post(url, { ...channelData });
  };

const EMDoUpdatEmailTemplate = emailTemplateData => {
    let url = `/network/update_email_templates/${emailTemplateData.id}/`;
    return axios.put(url, { ...emailTemplateData });
  };

  const EMDoUpdateEmailTemplateStatus =( {data, id}) => {
    let url = `/network/email_template_status/${id}/`;
    return axios.put(url, { ...data });
  };
const EMDOGetEmailTemplateList = queryData => {
    let url = "";
    if (queryData) {
      url = `/network/email_templates/${queryData}`;
    } 
    return axios.get(url);
  };
  
const EMDoCreateEmailTemplate = ({emailTemplateData,contextData}) => {
  
    let url = `/network/email_templates/?context_type=${contextData.contextType}&context=${contextData.context}`;
    return axios.post(url, { ...emailTemplateData });
  };



const EMDOGetEmailTemplateTypeList = ({ contextData }) => {
    let url = `/network/list_email_template_types/?context_type=${contextData.contextType}&context=${contextData.context}`;
    return axios.get(url);
    };

const EMDoGetGroupListDropDown = ({queryData}) => {
    let url = "";
    if (queryData) {
      url = `/group/group_list/${queryData}`;
    } 
    return axios.get(url);
  };
  /* email - Template Functionality Api */
const EMDoGetEmailTemplate = ({ contextData }) => {
    let url = `/network/list_email_template_templates/?context_type=${contextData.contextType}&context=${contextData.context}`;
    return axios.get(url);
    };

  /* Organisation Management Get and Put */
const EMDoGetNetworkListDetails = () => {
    let url = "/network/org_partnership_module_list/";
    return axios.get(url);
};
const EMDoGetNetworkDetailsList = ({contextData}) => {
   
  let url = `/network/org_partnership_module_update/?context_type=${contextData.contextType}&context=${contextData.context}`;
  return axios.get(url);
};

//Download attachments for channel
const EMDoDownloadAttachmentsForNetwork = (data) => {
  
  let url = `network/${data.context}/download-network-attachments/?entity=${data.contextType}`
  return axios.get(url,  
    {
    responseType: 'arraybuffer'
})
}

const EMDOUpdateNetworkDetails = ({ data,contextData })=>{
   
  const url = `/network/org_partnership_module_update/?context_type=${contextData.contextType}&context=${contextData.context}`;
  delete(data.contextData)
  delete(data.files)
  const fileData = new FormData();
fileData.append("additionalInfo", data.additionalInfo)
  fileData.append("address", data.address)
  fileData.append("city", data.city)
  fileData.append("country", data.country)
  fileData.append("email", data.email)
  fileData.append("key", data.key)
  fileData.append("name", data.name)
  fileData.append("vision", data.vision)
  fileData.append("mission", data.mission)
  fileData.append("phone", data.phone)
  fileData.append("showmsg", data.showmsg)
  fileData.append("tagline", data.tagline)
  fileData.append("website", data.website)
  fileData.append("zipCode", data.zipCode)
  fileData.append("state", data.state)
  fileData.append("deleted_attachments", data.deleted_attachments)
  data.new_attachments.map(img => {
    fileData.append("new_attachments", img)
  })
  return axios.put(url, fileData,  {body: {
    'Content-Type': 'multipart/form-data'
}})
}

const EMDoGetFileUploadDetails = ({contextData}) => {
   
  let url = `/network/${contextData.entity}/upload-files/${contextData.context}/`;
  return axios.get(url);
};

const EMNetworkDetailsUpdateFileUpload = ({files,contextData})=>{
   
  const fileData = new FormData()
  if(files.hasOwnProperty("icon")){fileData.append("logo_file",files.icon)}
  if(files.hasOwnProperty("favicon")){fileData.append("favicon_file",files.favicon[0])}
  if(files.hasOwnProperty("policy")){fileData.append("deandi_policy_file",files.policy[0])}
  let url = `/network/${contextData.entity}/upload-files/${contextData.context}/`;
  return axios.put(url,fileData,{
    headers : {
      'Content-Type': 'multipart/form-data'
    }
  })
  
}
/* Put - update Group Status */
const EMDoUpdateNetworkAdminGroupStatus = ({ data,id,contextData }) => {
  
  let url = `group/group_status_update/?id=${id}&context=${contextData.context}&context_type=${contextData.contextType}`;
  return axios.put(url, data)
}

/* Manage Roles and Permissions */
const EMDoListRolesForSelectedEntity = ({data,query})=>{
  let url 
  if(data.entity =="partnership"){
    data.entity = "network"
  }
  if(data != undefined){
    if(query){
      url = `${data.entity}/${data.id}/roles${query}`
    }else{
      url = `${data.entity}/${data.id}/roles`
    }
  }
  return axios.get(url)
}
const EMDoCreateNewRoleForEntity =({ roleData, contextData }) =>{
  if(contextData.entity =="partnership"){
    contextData.entity = "network"
  }
  let url=`${contextData.entity}/${contextData.id}/roles`
  return axios.post(url, { ...roleData });
}

const EMDoRetrievePermissionsForRole = ({contextData}) =>{
  if(contextData.entity =="partnership"){
    contextData.entity = "network"
  }
  let url= `${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`
  return axios.get(url)
}
const EMDoUpdateStatusOfRole = ({contextData})=>{
  if(contextData.entity =="partnership"){
    contextData.entity = "network"
  }
  let url = `${contextData.entity}/${contextData.entityId}/roles/${contextData.roleId}/action:${contextData.action}/`
  return axios.patch(url)
}
const EMDoUpdateRoleForEntity =({ roleData, contextData }) =>{
  if(contextData.entity =="partnership"){
    contextData.entity = "network"
  }
  let url=`${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`
  return axios.put(url, { ...roleData });
}

// API for dashboard
const EMDoGetNetworkDashboardInfo = (requiredData) => {
  let url
  if (requiredData && requiredData.isPartnership) {
    url = `/user/network-admin-network-dashboard/partnership/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
  }else{
    url = `/user/network-admin-network-dashboard/organization/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
  }

  return axios.get(url);
};
const EMDoGetNetworkListForDashboardAndReportsForNetwork = () => {
  let url = `/user/list-user-orgs-and-partnerships/`;
  return axios.get(url);
};

const EMDoGetNetworkOrgGrpChnForNetwork = () => {
  let url = `/user/network-admin-entity-list/`;
  return axios.get(url);
};

// API FOR REPORTS
const EMDoGetOnboardingReportForNetwork = (requiredData) => {
  let 
    url = `/user/platform-admin-onboarding-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
  
  return axios.get(url);
};

const EMDoExportOnboardingReportForNetwork  = (requiredData) => {
  let url = `/user/network-admin-onboarding-report/`;
  
  return axios.post(url, requiredData);
};

const EMDoGetUsageReportForNetwork = (requiredData) => {
  let url=`/user/network-admin-usage-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
  return axios.get(url,requiredData);
};

const EMExportUsageReportForNetwork = (requiredData) => {
  let url = `/user/network-admin-usage-report/`;
  return axios.post(url, requiredData);
};

const EMDoGetUserEngagementReportForNetwork = (requiredData, paginationData) => {
  let url
  if(requiredData.groupOrChannel == 1){
    if(requiredData && requiredData.isPartnership){
      url = `/user/network-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`
     }else {
       url = `/user/network-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
     }
  }else {
    if(requiredData && requiredData.isPartnership){
      url = `/user/network-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`
     }else {
       url = `/user/network-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
     }
  }
  return axios.get(url);


};

const EMDoExportUserEngagementReportForNetwork = (requiredData, groupOrChannel) => {
  let url
  if(groupOrChannel == 1){
    url = `/user/network-admin-user-engagement-report/group/`
  }else{
    url = `/user/network-admin-user-engagement-report/channel/`
  }
  return axios.post(url, requiredData);
};
const EMDoGetNetworkStatsReportForNetwork = (requiredData, paginationData) => {
   
    let url = `/user/network-admin-network-stats-report/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
  
  return axios.get(url);
};

const EMDoExportNetworkStatsReportForNetwork = (requiredData) => {
  let url = `/user/network-admin-network-stats-report/`;
  return axios.post(url, requiredData);
};

const EMDoGetListOfOrganizationDepartments = (organizationId, queryData) => {
  let url = `/organization/${organizationId}/list-add-department/${queryData}`
  return axios.get(url)
}

const EMDoCreateNewDepartmentInOrganization = (data) => {
  
  let url = `/organization/${data.organizationId}/list-add-department/`
  const fileData = new FormData()
  if(data.hasOwnProperty("department")){fileData.append("department",data.department)}
  return axios.post(url,fileData)
}

const EMDoUpdateDepartmentInOrganization = (data) => {
  let url = `/organization/${data.organizationId}/update-department/${data.departmentId}/`
  const fileData = new FormData()
  if(data.hasOwnProperty("department")){fileData.append("department",data.department)}
  return axios.patch(url,fileData)
}

const EMDoDeleteDepartmentInOrganization = (data) => {
  let url = `/organization/${data.organizationId}/delete-department/${data.departmentId}/`
  return axios.delete(url)
}

const EMDoGetListOfOrganizationLevels = (organizationId, queryData) => {
  
  let url = `/organization/${organizationId}/list-add-level/${queryData}`
  return axios.get(url)
}

const EMDoCreateNewLevelInOrganization = (data) => {
  
  let url = `/organization/${data.organizationId}/list-add-level/`
  const fileData = new FormData()
  if(data.hasOwnProperty("level")){fileData.append("level",data.level)}
  return axios.post(url,fileData)
}

const EMDoUpdateLevelInOrganization = (data) => {
  
  let url = `/organization/${data.organizationId}/update-level/${data.levelId}/`
  const fileData = new FormData()
  if(data.hasOwnProperty("level")){fileData.append("level",data.level)}
  return axios.patch(url,fileData)
}

const EMDoDeleteLevelInOrganization = (data) => {
  
  let url = `/organization/${data.organizationId}/delete-level/${data.levelId}/`
  return axios.delete(url)
}

const EMDoGetDefaultLevelsInOrganization = () => {
  let url = `/gamification/get_default_levels/`
  return axios.get(url)
}

const EMDoGetDefaultBadgesInOrganization = () => {
  let url = `/gamification/get_default_badges/`
  return axios.get(url)
}

const EMDoGetDataForDropdownInGamification = (entityType) => {
  let url = `/gamification/${entityType}/get_user_entity/`
  return axios.get(url)
}

const EMDoGetLevelAndBadgeReport = (entityType, entityId, reportType) => {
  let url = `/gamification/${entityType}/${entityId}/generate-report/${reportType}/`
  return axios.get(url)
}

//get list of networks for dropdown

const EMDoGetListOfNetworksForDropdown = () => {
  let url = `/auth/get-entity-for-dropdown/organization-partnership/none/none/`
  return axios.get(url)
}

//get permission for user, specific to group
const EMDoGetPermissionSpecificToNetwork = (id, networkType) => {
  let url;
  if(id && networkType == "organization"){
    url = `auth/get-roles/organization/${id}/`
    return axios.get(url)
  }else if(id && networkType == "partnership"){
    url = `auth/get-roles/partnership/${id}/`
    return axios.get(url)
  }
}

//get deafult email body using template id
const EMDoGetDefaultEmailTemplate = (data) => {
  let { templateId, contextType, contextId } = data
  let url = `commans/get-email-template-data/${templateId}/${contextType}/${contextId}/`
  return axios.get(url)
}

export default {
  EMDoGetNetworkListDetails,
  EMDoUpdateNetworkAdminGroup,
  EMDoCreateNetworkAdminGroup,
  EMDoGetNetworkAdminGroupList,
  EMDoUpdateNetworkAdminChannel,
  EMDoCreateNetworkAdminChannel,
  EMDoGetNetworkAdminChannelList,
  EMDoUpdatEmailTemplate,
  EMDoCreateEmailTemplate,
  EMDOGetEmailTemplateTypeList,
  EMDoGetGroupListDropDown,
  EMDoGetNetworkAdminUsersList,
  EMDoUpdateNetworkAdmin,
  EMDoCreateNetworkAdmin,
  EMDoExportNetworkAdminUsers,
  EMDoResendInviteToUsers,
  EMDoGetResendInviteUsersList,
  EMDoGetAllImportUsersList,
  EMDoImportAllSelectedUsersList,
  EMDoBulkUploadUsersList,
  EMDoGetRolesList,
  EMDOGetEmailTemplateList,
  EMDOUpdateNetworkDetails,
  EMDoGetNetworkDetailsList,
  EMDoGetFileUploadDetails,
  EMNetworkDetailsUpdateFileUpload,
  EMDoGetFilterByGroupDropdownValues,
  EMDoGetFilterByGroupList,
  EMDoGetFilterByGroupListById,
  EMDoUpdateEmailTemplateStatus,
  EMDoGetEmailTemplate,
  EMDoUpdateNetworkAdminGroupStatus,
  //ManageRolesAndPermissions
  EMDoListRolesForSelectedEntity,
  EMDoCreateNewRoleForEntity,
  EMDoRetrievePermissionsForRole,
  EMDoUpdateStatusOfRole,
  EMDoUpdateRoleForEntity,
  EMDoGetNetworkDashboardInfo,
  EMDoGetNetworkOrgGrpChnForNetwork,
  EMDoGetNetworkListForDashboardAndReportsForNetwork,
  EMDoExportOnboardingReportForNetwork,
  EMDoGetUsageReportForNetwork,
  EMExportUsageReportForNetwork,
  EMDoGetUserEngagementReportForNetwork,
  EMDoExportUserEngagementReportForNetwork,
  EMDoGetOnboardingReportForNetwork,
  EMDoGetNetworkStatsReportForNetwork,
  EMDoExportNetworkStatsReportForNetwork,
  EMDoDownloadAttachmentsForNetwork,
  EMDoGetListOfOrganizationDepartments,
  EMDoGetListOfOrganizationLevels,
  EMDoCreateNewDepartmentInOrganization,
  EMDoCreateNewLevelInOrganization,
  EMDoUpdateDepartmentInOrganization,
  EMDoDeleteDepartmentInOrganization,
  EMDoUpdateLevelInOrganization,
  EMDoDeleteLevelInOrganization,
  EMDoGetDefaultLevelsInOrganization,
  EMDoGetDefaultBadgesInOrganization,
  EMDoGetDataForDropdownInGamification,
  EMDoGetLevelAndBadgeReport,
  EMDoGetListOfNetworksForDropdown,
  EMDoGetPermissionSpecificToNetwork, 
  EMDoGetDefaultEmailTemplate
};
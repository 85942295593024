import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  SvgIcon,
  IconButton,
  Tooltip,
  useMediaQuery,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import EMPage from "../../../../components/commans/EMPage";
import EMSearchView from "../../../../components/navigation/EMSearchView";
import { useSelector, useDispatch } from "react-redux";
import EMGroupView from "../../../../components/user/EMGroupView";
import {
  ADD_SUBTABS_TO_GROUP,
  CHANGE_ELEMENT_FOR_SUBTABS,
} from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import {
  EMDoActionForChannelAction,
  EMDoGetListOfMyChannelsAction,
  EMDoGetListOfMyChannelsBySearchAction,
  EMDoGetListOfMyJointChannelsAction,
  EMDoGetListOfMyJointChannelsBySearchAction,
} from "../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import partnershipImage from "../../../../assets/group_image.jpg";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { CHANNEL_INFO_MODAL, DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS, DISABLE_ENABLE_SEARCH_JOINT_CHANNELS } from "../../../../redux/constants/UserPlatform/EMChannelConstant";
import { find, isEmpty } from "lodash";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { EMDoMarkHighlightedChannelAsSeenAction } from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../../redux/actions/EMAuthActions";
const useStyles = makeStyles((theme) => ({
  topBar: {
    float: "right",
    display: "flex",
    marginLeft: "59%",
  },
  paper: {
    paddingLeft: "1%",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
  },
  networkInfo: {
    textAlign: "center",
    // marginTop: "25%",
  },
  gridListTile: {
    marginTop: "4px",
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  rootForGroups: {
    // marginTop: "2%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforGroups: {
    width: "100%",
    height: "100%",
    padding: 10,
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      height: "0em",
      width: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    width: "100%",
    // height: window.screen.height/7,
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo1: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color:"#2F92D6"
  },
}));

function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
    </SvgIcon>
  );
}

function LatestPost(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const uiData = useSelector((state) => state.ui || {});
  const data = useSelector((state) => state.channels || {});
  console.log("allChannels",data)
  const [search, setSearch] = React.useState("");
  const [joinsearch, setJoinsearch] = React.useState("");
  const [elRefs, setElRefs] = React.useState([]);
  const [jointChannelRef, setJointChannelRef] = React.useState([]);
  const arrLength = data.myChannelGroupNo;
  const jointArrLength = data.myJointChannelGroupNo;

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(EMDoGetListOfMyChannelsAction());
    dispatch(EMDoGetListOfMyJointChannelsAction());
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
    dispatch({
      type:DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS,
      payload:true
    })
    dispatch({
      type:DISABLE_ENABLE_SEARCH_JOINT_CHANNELS,
      payload:true
    })
  }, []);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 2.5;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 2.5;
    }
    if (isWidthUp("md", screenWidth)) {
      return 1.8;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 1.5;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1.1;
    }
    return 3.5;
  }
  const cols = getCols(props.width);

  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [arrLength]);

  React.useEffect(() => {
    setJointChannelRef((jointChannelRef) =>
      Array(jointArrLength)
        .fill()
        .map((_, i) => jointChannelRef[i] || React.createRef())
    );
  }, [jointArrLength]);

  return (
    <EMPage
      title="Groups"
      className={classes.paper1}
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={3}>
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2}>
            <Dialog
              // style={{minHeight:"80vh", minWidth:"80vh"}}
              open={data.channelInfoPopUp.value}
              onClose={() => {
                dispatch({
                  type: CHANNEL_INFO_MODAL,
                  payload: { value: false },
                });
              }}
            >
              <DialogContent style={{ width: "40vh" }}>
                <div style={{ marginBottom: "5%" }}>
                  <div style={{ display: "flex" }}>
                    <Typography color="textSecondary">Network Name</Typography>
                    <DeleteIcon
                      style={{ position: "absolute", top: 20, right: 10 }}
                      onClick={() => {
                        dispatch({
                          type: CHANNEL_INFO_MODAL,
                          payload: { value: false },
                        });
                      }}
                    />
                  </div>

                  <Typography
                    style={{
                      fontWeight: "bolder",
                      fontSize: "small",
                      marginTop: "3%",
                    }}
                  >
                    {data.channelInfoPopUp &&
                      data.channelInfoPopUp.network_name}
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary">Created By</Typography>
                  <div style={{ display: "flex", marginTop: "3%" }}>
                    <Avatar
                      src={
                        data.channelInfoPopUp.groupInfo &&
                        data.channelInfoPopUp.groupInfo.profile_pic_file &&
                        process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                          0,
                          -1
                        ) + data.channelInfoPopUp.groupInfo.profile_pic_file
                      }
                    ></Avatar>
                    <Typography
                      style={{
                        fontWeight: "bolder",
                        fontSize: "small",
                        marginTop: "3%",
                        marginLeft: "3%",
                      }}
                    >
                      {data.channelInfoPopUp.groupInfo &&
                        data.channelInfoPopUp.groupInfo.created_by}
                    </Typography>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <Paper style={{ padding: "2%" }}>
              <Grid container>
                <Grid item lg={6} md={6} sm={7} xs={8}>
                  <Typography
                    style={{ fontSize: "medium", fontWeight: "bolder" }}
                  >
                    My internal channels ({data.myChannelLength})
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={5} xs={4}>
                  <div
                    style={{
                      display: "flex",
                      float: "right",
                    }}
                    justifyContent="flex-end"
                  >
                
                      <EMSearchView
                      disabled={data.isSearchDisabledInternalChannels}
                        route={true}
                        data={joinsearch}
                        hint={"Search"}
                        onChangeListener={(data) => {
                          setSearch(data);
                          dispatch(
                            EMDoGetListOfMyChannelsBySearchAction({
                              searchText: data,
                            })
                          );
                          dispatch({
                            type:DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS,
                            payload:true
                          })
                        }}
                        usedIn="joint_groups"
                      />
                
                  </div>
                </Grid>
              </Grid>
              {data.channels.map((jointGroup, index) => (
                <Paper
                  elevation={2}
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginTop: "2%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {matchesSmallScreen ? (
                    <Box
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "1%",
                      }}
                    >
                      <Typography
                        className={classes.typo}
                        style={{ fontWeight: "bolder", fontSize: "small" }}
                      >
                        {jointGroup.group_name}
                      </Typography>
                    </Box>
                  ) : (
                    <Card style={{ width: "170px", padding: "1%" }}>
                      <div className={classes.networkInfo}>
                        <div
                          style={{
                            width: "100%",
                            height: "170px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              height: "100%",
                              verticalAlign: "middle",
                            }}
                          ></span>
                          <img
                            alt=""
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              verticalAlign: "middle",
                            }}
                            src={
                              jointGroup.logo_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + jointGroup.logo_file
                                : partnershipImage
                            }
                          />
                        </div>
                        <Tooltip
                          title={
                            <h2 style={{ color: "white" }}>
                              {jointGroup.group_name}
                            </h2>
                          }
                          placement="top"
                        >
                          <Typography
                            className={classes.typo}
                            style={{ fontWeight: "bolder", marginTop: "1%" }}
                          >
                            {jointGroup.group_name}
                          </Typography>
                        </Tooltip>
                      </div>
                    </Card>
                  )}
                  {!matchesSmallScreen ? (
                    <IconButton
                      id={index}
                      onClick={() => {
                        if (
                          elRefs[index].current &&
                          elRefs[index].current.scrollLeft !==
                            elRefs[index].current.scrollWidth
                        ) {
                          elRefs[index].current.scrollLeft -=
                            elRefs[index].current.scrollWidth / 10;
                        }
                      }}
                    >
                      <NavigateBeforeIcon
                        fontSize="small"
                        style={{ color: "black" }}
                      />
                    </IconButton>
                  ) : null}

                  <div
                    className={
                      jointGroup.channel.length >= 2
                        ? classes.root
                        : classes.rootForGroups
                    }
                  >
                    <GridList
                      key={jointGroup.id}
                      cellHeight={180}
                      className={
                        jointGroup.channel.length >= 2
                          ? classes.gridList
                          : classes.gridListforGroups
                      }
                      cols={cols}
                      ref={elRefs[index]}
                      style={{ overflowY: "hidden" }}
                    >
                      {jointGroup.channel &&
                        jointGroup.channel
                        .filter(channel => channel?.is_member === true)
                        .map((channel, key) => (
                          <GridListTile
                            key={channel.id}
                            className={classes.gridListTile}
                            style={{
                              marginLeft: "1%",
                              //  width: "32%",
                              marginTop: "2%",
                              height: "150px",
                            }}
                          >
                            <EMGroupView
                              onStarClick={() => {
                                let actions;
                                channel.is_fav
                                  ? (actions = `remove-favorite`)
                                  : (actions = `favorite`);
                                dispatch(
                                  EMDoActionForChannelAction({
                                    id: channel.id,
                                    myaction: actions,
                                    postIndex: key,
                                    route: "internal_channels",
                                  })
                                );
                              }}
                              id={channel.id}
                              type={CHANNEL_INFO_MODAL}
                              value={data.channelInfoPopUp.value}
                              number_of_group_users={
                                channel.number_of_channel_users
                              }
                              group_name={channel.channel_name}
                              logo_file={channel.logo_file}
                              isHighlighted={channel.is_highlited}
                              isFav={channel.is_fav}
                              created_by={channel.created_by}
                              organization_name={channel.organization}
                              // users_count={channel.number_of_channel_users}
                              privacy_type_name={channel.privacy_type_name}
                              dataForGroup={channel}
                              onCardClick={() => {
                                if (channel.is_highlited === true) {
                                  dispatch(
                                    EMDoMarkHighlightedChannelAsSeenAction(
                                      channel.id
                                    )
                                  );
                                }
                                dispatch(
                                  EMGetRolesAndPermissionsBasedOnEntityAction({
                                    entity: "channel",
                                    entityId: channel.id,
                                  })
                                );
                                window.sessionStorage.setItem(
                                  "ChannelId",
                                  channel.id
                                );
                                navigation("activity", {
                                  state: { ChannelId: channel.id },
                                });
                              }}
                            />
                          </GridListTile>
                        ))}
                    </GridList>
                  </div>
                  {!matchesSmallScreen ? (
                    <IconButton
                      id={index}
                      onClick={() => {
                        if (
                          elRefs[index].current &&
                          elRefs[index].current.scrollLeft !==
                            elRefs[index].current.scrollWidth
                        ) {
                          elRefs[index].current.scrollLeft +=
                            elRefs[index].current.scrollWidth / 10;
                        }
                      }}
                    >
                      <NavigateNextIcon
                        fontSize="small"
                        style={{ color: "black" }}
                      />
                    </IconButton>
                  ) : null}
                </Paper>
              ))}
            </Paper>
            <Backdrop
                className={classes.backdrop}
                open={uiData.loading.state}
                onClick={() => {}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            <Paper style={{ padding: "2%" }}>
              <div style={{ display: "flex" }}>
                <Grid container>
                  <Grid item lg={6} md={6} sm={7} xs={8}>
                    <Typography
                      style={{ fontSize: "medium", fontWeight: "bolder" }}
                    >
                      My Joint channels ({data.jointChannelLength})
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={5} xs={4}>
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                      }}
                      justifyContent="flex-end"
                    >
                      
                        <EMSearchView
                        disabled={data.isSearchDisabledJointChannels}
                          route={true}
                          data={joinsearch}
                          hint={"Search"}
                          onChangeListener={(data) => {
                            setJoinsearch(data);
                            dispatch(
                              EMDoGetListOfMyJointChannelsBySearchAction({
                                searchText: data,
                              })
                            );
                            dispatch({
                              type:DISABLE_ENABLE_SEARCH_JOINT_CHANNELS,
                              payload:true
                            })
                          }}
                          usedIn="joint_groups"
                        />
                
                    </div>
                  </Grid>
                </Grid>
              </div>
              {data.jointChannels.map((myChannels, index) => (
                <Paper
                  elevation={2}
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginTop: "2%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {matchesSmallScreen ? (
                    <Box
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "1%",
                      }}
                    >
                      <Typography
                        className={classes.typo1}
                        style={{ fontWeight: "bolder", fontSize: "small" }}
                      >
                        {myChannels.group_name},
                      </Typography>
                      &nbsp;
                      <Typography
                        className={classes.typo1}
                        style={{ fontSize: "small" }}
                      >
                        {myChannels.network_name}
                      </Typography>
                    </Box>
                  ) : (
                    <Card style={{ width: "170px", padding: "1%" }}>
                      <div className={classes.networkInfo}>
                        <div
                          style={{
                            width: "100%",
                            height: "170px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              height: "100%",
                              verticalAlign: "middle",
                            }}
                          ></span>
                          <img
                            alt=""
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              verticalAlign: "middle",
                            }}
                            src={
                              myChannels.logo_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + myChannels.logo_file
                                : partnershipImage
                            }
                          />
                        </div>
                        <Tooltip
                          title={
                            <h2 style={{ color: "white" }}>
                              {myChannels.group_name}
                            </h2>
                          }
                          placement="top"
                        >
                          <Typography
                            className={classes.typo1}
                            style={{ fontWeight: "bolder", marginTop: "1%" }}
                          >
                            {myChannels.group_name}
                          </Typography>
                        </Tooltip>
                        <Tooltip
                          title={
                            <h2 style={{ color: "white" }}>
                              {myChannels.network_name}
                            </h2>
                          }
                          placement="top"
                        >
                          <Typography
                            style={{ marginTop: "1%" }}
                            className={classes.typo1}
                          >
                            {myChannels.network_name}
                          </Typography>
                        </Tooltip>
                      </div>
                    </Card>
                  )}
                  {!matchesSmallScreen ? (
                    <IconButton
                      id={index}
                      onClick={() => {
                        if (
                          jointChannelRef[index].current &&
                          jointChannelRef[index].current.scrollLeft !==
                            jointChannelRef[index].current.scrollWidth
                        ) {
                          jointChannelRef[index].current.scrollLeft -=
                            jointChannelRef[index].current.scrollWidth / 10;
                        }
                      }}
                    >
                      <NavigateBeforeIcon
                        fontSize="small"
                        style={{ color: "black" }}
                      />
                    </IconButton>
                  ) : null}

                  <div
                    className={
                      myChannels.channel.length > 2
                        ? classes.root
                        : classes.rootForGroups
                    }
                  >
                    <GridList
                      key={myChannels.id}
                      // cellHeight={180}
                      className={
                        myChannels.channel.length > 2
                          ? classes.gridList
                          : classes.gridListforGroups
                      }
                      cols={cols}
                      ref={jointChannelRef[index]}
                      style={{ overflowY: "hidden" }}
                    >
                      {myChannels.channel &&
                        myChannels.channel
                        .filter(channel => channel?.is_member === true)
                        .map((channel, key) => (
                          <GridListTile
                            key={channel.id}
                            className={classes.gridListTile}
                            style={{
                              marginLeft: "1%",
                              // width: "32%",
                              marginTop: "2%",
                              height: "150px",
                            }}
                          >
                            <EMGroupView
                              onStarClick={() => {
                                let actions;
                                channel.is_fav
                                  ? (actions = `remove-favorite`)
                                  : (actions = `favorite`);
                                dispatch(
                                  EMDoActionForChannelAction({
                                    id: channel.id,
                                    myaction: actions,
                                    postIndex: key,
                                    route: "joint_channels",
                                  })
                                );
                              }}
                              id={channel.id}
                              type={CHANNEL_INFO_MODAL}
                              value={data.channelInfoPopUp.value}
                              number_of_group_users={
                                channel.number_of_channel_users
                              }
                              group_name={channel.channel_name}
                              logo_file={channel.logo_file}
                              isHighlighted={channel.is_highlited}
                              isFav={channel.is_fav}
                              created_by={channel.created_by}
                              organization_name={channel.organization}
                              // users_count={channel.number_of_channel_users}
                              privacy_type_name={channel.privacy_type_name}
                              group={channel}
                              onCardClick={() => {
                                if (channel.is_highlited === true) {
                                  dispatch(
                                    EMDoMarkHighlightedChannelAsSeenAction(
                                      channel.id
                                    )
                                  );
                                }
                                dispatch(
                                  EMGetRolesAndPermissionsBasedOnEntityAction({
                                    entity: "channel",
                                    entityId: channel.id,
                                  })
                                );
                                window.sessionStorage.setItem(
                                  "ChannelId",
                                  channel.id
                                );
                                navigation("activity", {
                                  state: { ChannelId: channel.id },
                                });
                              }}
                            />
                          </GridListTile>
                        ))}
                    </GridList>
                  </div>
                  {!matchesSmallScreen ? (
                    <IconButton
                      id={index}
                      onClick={() => {
                        if (
                          jointChannelRef[index].current &&
                          jointChannelRef[index].current.scrollLeft !==
                            jointChannelRef[index].current.scrollWidth
                        ) {
                          jointChannelRef[index].current.scrollLeft +=
                            jointChannelRef[index].current.scrollWidth / 10;
                        }
                      }}
                    >
                      <NavigateNextIcon
                        fontSize="small"
                        style={{ color: "black" }}
                      />
                    </IconButton>
                  ) : null}
                </Paper>
              ))}
            </Paper>
          </Paper>
        </Grid>
        <Paper>
          <Grid item lg={1}></Grid>
        </Paper>
      </Grid>
    </EMPage>
  );
}
export default withWidth()(LatestPost);

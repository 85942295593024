import React, { useEffect, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
    EMSimpleTextFieldView,
    EMChipTextFieldView,
} from "../../../../components/EMTextFieldView";
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    makeStyles,
    Link,
} from "@material-ui/core";
import EMFileUploader from "../../../../components/commans/EMFileUploader"
import EMTextArea from "../../../../components/commans/EMTextArea"
import EMCountryAndStateSelector from "../../../../components/commans/EMCountryAndStateSelector"
import parse from 'html-react-parser';
import EMImageCropper from "../../../../components/commans/EMImageCropper";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 0)
    },
    header: {
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    textField: {
        marginBottom: "2%"
    },
    spacing: {
        marginBottom: "5%",

    },
    paper: {
        marginLeft: "2%",
        marginRight: "2%",
    }
}));

export default function EMOrganisationDetails({ labelDetails,
    nameId,
    nameValue,
    onChange,
    nameFormikError,
    nameHelperText,
    taglineId,
    taglineFormikError,
    taglineHelperText,
    visionHandler,
    missionHandler,
    iconChangeHandler,
    iconFileName,
    faviconFileName,
    faviconChangeHandler,
    faviconId,
    iconId,
    tagValue,
    visionValue,
    missionValue,
    previconFileName,
    previconFileLink,
    prevfaviconFileName,
    prevfaviconFileLink,
    entityId,
    manageBrowse
}) {
    let icon;
    if (previconFileName) {
        icon =<><p style = {{marginBottom : "2%"}}> {"Previous Icon File is : "} <Link
            variant="body2"
            href={previconFileLink}
            target="_blank"
            rel="noopener"
            download={previconFileName}
        >
            {previconFileName}
        </Link></p></>
    }
    let favicon;
    if (prevfaviconFileName) {
        favicon =<><p style = {{marginBottom : "2%"}}> {"Previous Favicon File is : "} <Link
            variant="body2"
            href={prevfaviconFileLink}
            target="_blank"
            rel="noopener"
            download={prevfaviconFileName}
        >
            {prevfaviconFileName}
        </Link></p></>
    }
    const classes = useStyles();
    return (
        <Paper item className={classes.paper}>
            <Typography className={classes.title}>
                {labelDetails}
            </Typography>
            <Box className={classes.paper}>
                <EMSimpleTextFieldView
                    className={classes.textField}
                    label={"Name"}
                    id={nameId}
                    name={nameId}
                    value={nameValue}
                    onChange={onChange}
                    // error={nameFormikError}
                    helperText={nameHelperText}
                />
                <EMSimpleTextFieldView
                    label={"TagLine"}
                    value={tagValue}
                    id={taglineId}
                    name={taglineId}
                    onChange={onChange}
                    error={taglineFormikError}
                    helperText={taglineHelperText}
                />
                <Typography className={classes.header}>
                    {"Logo"}
                </Typography>
                <EMImageCropper
                entityId={entityId}
                manageBrowse={manageBrowse}
                onCancel={iconFileName}
                 id={iconId}
                 changeHandler={iconChangeHandler}
                  accept={[".png", ".jpg", ".jpeg"]}
                  fileSize={11534336}
                  supportedFormats={["image/png", "image/jpg", "image/jpeg"]}
                  maxFileSize={"10MB"}
                  aspectRatio={150/150}
                  minfilesize={71680}
                  minFileSize={"70KB"}
                />
                    {icon}
                    <Typography className={classes.header}>
                        {"Favicon"}
                    </Typography>
                    <EMFileUploader
                        id={faviconId}
                        changeHandler={faviconChangeHandler}
                        fileName={faviconFileName}
                        accept={[".png",".jpeg",".jpg", ".ico"]}
                        fileSize ={11534336}
                        supportedFormats={["image/vnd.microsoft.icon","image/x-icon", "image/png","image/jpeg","image/jpg"]}
                        maxFileSize = {"10MB"}
                    />
                    {favicon}
                <Box className={classes.spacing}>
                    <EMTextArea placeHolder="Vision" toolbarId={1} changeHandler={visionHandler} value={visionValue} />
                    {/* {vision}  */}
                </Box>
                <Box className={classes.spacing} toolbarId={2}>
                    <EMTextArea placeHolder="Mission" changeHandler={missionHandler} value={missionValue} />
                    {/* {mission} */}
                </Box>
            </Box>
        </Paper>
    )
}
import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import {
  EMDoCreateNetworkAdminAction,
  EMDoUpdateNetworkAdminAction,
  EMDOGetNetworkAdminUsersListAction,
} from "../../../../../../redux/actions/EMNetworkAdminActions";
import { useDispatch, useSelector } from "react-redux";
import EMEntityRoleDropdown from "../../../commons/EMEntityRoleDropdown";
import EMEntityTemplateDropdown from "../../../commons/EMEntityTemplateDropdown";
import { findLast } from "lodash";
import EMMultiSelect from "../../../../../../components/commans/EMMultiSelect";
import EMSingleSelect from "../../../../../../components/commans/EMSingleSelect";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      // position: 'fixed',
      marginTop: "22rem",
      transform: "translate(0, -50%)",
      width: "70%",
      marginLeft: "5rem",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddNewUser(props) {
  const classes = useStyles();
  const [roleId, setRoleId] = React.useState("");
  const [templateId, setTemplateId] = React.useState("");
  const dispatch = useDispatch();
  let incorrectInput = "Only alphabets are allowed for this field ";
  let isDisabled = props.isEntityOrganisation
    ? false
    : props.permissionData && props.permissionData["edit_flag"];

  const userData = useSelector((state) => state.auth);
  const commonData = useSelector((state) => state.common || {});
  let loginUser = userData.email;

  let isRoleDisabled = props.userData["email"]
    ? props.userData["email"] == loginUser
    : false;

  const editMode = props.userData["email"]
    ? {}
    : { template: yup.object().required("Template is required") };

  const validationSchema = yup.object({
    ...editMode,
    first_name: yup
      .string("Enter first name")
      .trim()
      
      .required("First name is required"),
    last_name: yup
      .string("Enter last name")
      .trim()
      .required("Last name is required")
      ,
    email: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .min(1, "Email Address must have at least 1 items")
      .required("Email is required"),
    role: yup.string()
      .required("Role is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name:
        props.userData["first_name"] && !props.doweHaveTemplate
          ? props.userData["first_name"]
          : "",
      last_name:
        props.userData["last_name"] && !props.doweHaveTemplate
          ? props.userData["last_name"]
          : "",
      email:
        props.userData["email"] && !props.doweHaveTemplate
          ? [props.userData["email"]]
          : [],
      role:
        props.userData["role"] && !props.doweHaveTemplate
          ? props.userData["role"]
          : "",
      template:
        props.userData["email_template"] && !props.doweHaveTemplate
          ? props.userData["email_template"]
          : "",
      department: props.userData["department"]
        ? props.userData["department"]
        : "",
      level: props.userData["organization_level"]
        ? props.userData["organization_level"]
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.userData && props.userData.id > 0) {
        // if (props.userData ){

        let newUserData = {
          user_id: props.userData.id,
          ...values,
          email: props.userData.email,
          role: roleId.id || props.userData.role,
          template: props.userData.email_template || templateId.id,
          is_active: props.userData.is_active,
        };
        let contextData = {
          contextType: props.entity.context_type,
          context: props.entity.context,
        };
        let data = {
          ...newUserData,
        };

        dispatch(
          EMDoUpdateNetworkAdminAction({
            data,
            contextData,
            key: "user_update",
          })
        );

        //dispatch(EMDOGetNetworkAdminUsersListAction(`?limit=5&context_type=${props.entity.context_type}&context=${ props.entity.id}`));
      } else {
        let newUserData = {
          ...values,
          email: values.email[0],
          role: roleId.id,
          template: templateId.id,
        };
        let contextData = {
          contextType: props.entity.context_type,
          context: props.entity.context,
        };
        let data = {
          ...newUserData,
        };
        // dispatch(EMDoCreateEntityRoleAction(data));
        dispatch(
          EMDoCreateNetworkAdminAction({
            data,
            contextData,
            key: "user_invite",
          })
        );
      }
    },
  });

  //reset the form after modal is closed
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  let template;
  if (props.doweHaveTemplate) {
    template = (
      <Grid xs style={{ marginLeft: 10 }}>
        <EMEntityTemplateDropdown
          disabled={props.doweHaveTemplate}
          name="template"
          id="template"
          onSelect={(id) => {
            setTemplateId(id);
            formik.setFieldValue("template", id)
          }}
          onChange={formik.handleChange}
          value={formik.values.template}
          // error={
          //   formik.touched.template && Boolean(formik.errors.template)
          // }
          helperText={formik.touched.template && formik.errors.template}
        />
      </Grid>
    );
  }
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 4px 0px 18px", backgroundColor: "#2F92D6" }}
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton onClick={props.close}>
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
            style={{ paddingTop: "16px" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"First Name"}
                id="first_name"
                name="first_name"
                disabled={isDisabled}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.first_name && Boolean(formik.errors.first_name)
                // }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"Last Name"}
                id="last_name"
                disabled={isDisabled}
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.last_name && Boolean(formik.errors.last_name)
                // }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs>
              <ChipInput
                name="email"
                id="email"
                type="email"
                label="Email Address"
                disabled={
                  (props.userData["email"] && !props.doweHaveTemplate) ||
                  isDisabled
                }
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
                value={formik.values.email}
                FormHelperTextProps={{ style: { color: "red" } }}
                InputLabelProps={{ style: { color: "#4d4d4d" } }}
                //InputLabelProps={{style: {fontFamily: "Arial" }}}
                //InputProps={{ style: {fontFamily: "Arial" } }}
                // error={
                //   formik.errors.email && formik.touched.email ? true : false
                // }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    formik.setFieldValue("email", [chip.toLowerCase()]);
                  }
                }}
                onDelete={() => {
                  formik.setFieldValue("email", []);
                }}
                fullWidth
              />
              <Grid container style={{ marginTop: 30 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <EMEntityRoleDropdown
                    // data = {}
                    name="role"
                    id="role"
                    onSelect={(id) => {
                      
                      setRoleId(id);
                      formik.setFieldValue("role", id.role_name)
                    }}
                    entity={props.entity}
                    // prevData = {preValue}
                    initialValue={formik.values.role}
                    // error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                    disabled={isRoleDisabled}
                  />
                </Grid>
                {template}
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.form} spacing={2} container direction="row">
            <Grid item xs={12} sm={12} md={6}>
              <EMSingleSelect
                label={"Select Center"}
                objKey={"department"}
                required={false}
                data={commonData.org_type_departments}
                value={formik.values.department}
                onChange={(selectedDepartment) => {
                  let department = selectedDepartment;
                  formik.setFieldValue("department", department);
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6}>
              <EMSingleSelect
                required={false}
                disabled={false}
                label={"Select Level"}
                objKey={"level"}
                data={commonData.org_type_levels}
                onChange={(selectedLevel) => {
                  let level = selectedLevel;
                  console.log("level",level)
                  formik.setFieldValue("level", level);
                }}
                value={formik.values.level}
                // error={Boolean(
                //   formikForUserData.touched.department &&
                //     formikForUserData.errors.department
                // )}
                // helperText={
                //   formikForUserData.touched.department &&
                //   formikForUserData.errors.department
                // }
              />
            </Grid> */}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                disabled={!props.shouldButtonActive}
                variant="contained"
                color="primary"
                type="submit"
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
